import React, { useState, useEffect } from 'react'
import { decrypt, encrypt } from '../../utils/decryption';
import colorsList from "../../data/noteColors.json";
import { MdCheck } from "react-icons/md";
import { IoIosColorPalette } from 'react-icons/io';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { DeleteRequest, PutRequest } from '../../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import { noteActions } from '../../store/note-slice';
import piniconsticky from "../../assets/icons/pin-icon-sticky.png";
import DeleteConfirmation from '../../popups/deleteConfirmation';

const StickyNote = (props) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const { trigger } = useSelector(state => state.note);
    const [updatedColor, setUpdatedColor] = useState(props.color);
    const [updatedNote, setUpdatedNote] = useState("");


    useEffect(() => {
        const decryptedNote = decrypt(props.note);
        if (decryptedNote !== updatedNote) {
            setUpdatedNote(decryptedNote);
        }
    }, [props.note]);

    function updateNoteHandler() {
        if (updatedNote !== decrypt(props.note)) {
            const encryptedNote = encrypt(updatedNote);
            PutRequest(`${process.env.REACT_APP_URL}/notes/${props._id}`, { note: encryptedNote })
                .then(response => {
                    // Update Redux store directly with the updated note
                    dispatch(noteActions.updateNote({
                        id: props._id,
                        note: encryptedNote,
                        color: updatedColor
                    }));
                })
                .catch(error => {
                    console.log("note error >>>", error);
                });
        }
    }

    // Handle delete
    function deleteHandler() {
        DeleteRequest(`${process.env.REACT_APP_URL}/notes/${props._id}`).then(response => {
            dispatch(noteActions.setTrigger(trigger + 1));
        }).catch(error => {
            console.log("note error >>>", error);
        });
    }

    // Handle color change
    function updateHandler(body) {
        PutRequest(`${process.env.REACT_APP_URL}/notes/${props._id}`, body).then(response => {
            dispatch(noteActions.setTrigger(trigger + 1));
        }).catch(error => {
            console.log("note error >>>", error);
        });
    }

    return (
        <div className='sticky-note-book overflow-hidden relative pt-[12px] px-[10px] group'>
            <div className='relative -rotate-2 duration-200 hover:rotate-0 group-even:rotate-2 hover:group-even:rotate-0'>
                <div className='w-[33px] absolute -top-[10px] left-0 right-0 mx-auto text-[35px] text-[#ff0000]'>
                    <img src={piniconsticky} width={24} alt="pin" />
                </div>
                <div className='absolute right-[10px] bottom-[10px] flex gap-[5px] justify-end'>
                    <button
                        onClick={() => setShow(prev => !prev)}
                        className='bg-[#594cda] text-[#fff] text-center rounded-full p-2'
                    ><IoIosColorPalette size={18} /></button>

                    <DeleteConfirmation
                        onClick={() => deleteHandler(props._id)}
                        description="Are you sure you want to delete this comment?"
                        component={
                            <div className='bg-[#eb427e] text-[#fff] text-center rounded-full p-2'>
                                <RiDeleteBin5Line size={18} />
                            </div>
                        }
                        disableCss={true}
                    />
                </div>

                <textarea
                    value={updatedNote}
                    className={`shadow px-[15px] pb-[45px] pt-[35px] w-full text-[#000] h-[220px] rounded outline-none placeholder-black`}
                    placeholder="write something....."
                    style={{ backgroundColor: props.color }}
                    onChange={(e) => {
                        setUpdatedNote(e.target.value);
                    }}
                    onBlur={updateNoteHandler}
                ></textarea>
            </div>
            <div className={`flex-wrap gap-[5px] ${show ? "flex" : "hidden"}`}>
                {colorsList.map((color, i) => (
                    <div key={i} className="relative">
                        <button
                            className='w-[30px] h-[30px] rounded-full bg-[#ffe4c4] text-[0px]'
                            style={{ backgroundColor: color }}
                            onClick={() => {
                                setUpdatedColor(color);
                                updateHandler({ color: color });
                            }}
                        >bisque</button>
                        {color === updatedColor && (
                            <MdCheck size={20} className='absolute top-[5px] ml-[5px]' />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StickyNote;
