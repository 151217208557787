import React, { useEffect } from 'react'
import { GetRequest } from '../../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import AddProject from '../../popups/addProject';
import getInitialLetter from '../../utils/getInitialLetter';
import { Link, useLocation } from 'react-router-dom';
import TaskSocket from "../../socket/task-socket";
import { projectActions } from '../../store/project-slice';

const Projects = ({ onClick = () => { } }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { accountInfo } = useSelector((state) => state.account);
    const { projects, activeProject, projectTrigger } = useSelector((state) => state.project);
    const { selectedTaskData } = useSelector((state) => state.task);

    const role = accountInfo?.data?.role?.title?.toLowerCase();

    TaskSocket.useTaskRoomSetup(accountInfo?.data?._id)
    TaskSocket.useProjectUpdate()

    useEffect(() => {
        if (accountInfo && accountInfo.data?.adminId) {
            GetRequest(`${process.env.REACT_APP_URL}/project/${accountInfo?.data._id}`).then((response) => {
                dispatch(projectActions.setProjects(response.data));
                if (!activeProject) {
                    dispatch(projectActions.setActiveProject(response.data[0]));
                }
            }).catch((error) => {
                console.error("Error fetching user details:", error);
            });
        }
        if (accountInfo && !accountInfo.data?.adminId) {
            GetRequest(`${process.env.REACT_APP_URL}/project/admin/${accountInfo?.data?._id}`).then((response) => {
                dispatch(projectActions.setProjects(response.data));
                if (!activeProject) {
                    dispatch(projectActions.setActiveProject(response.data[0]));
                }
            }).catch((error) => {
                console.error("Error fetching user details:", error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo, projectTrigger]);

    return (
        <div className='overflow-hidden overflow-y-auto py-[10px] px-[10px]'>
            <div className='flex justify-between items-center'>
                <div className='text-[12px] text-[#aaa] text-left'>
                    My Projects ({projects.length})
                </div>
                {(role === "admin" || accountInfo?.permissions?.addProject) && (
                    <AddProject trigger={() => dispatch(projectActions.setProjectTrigger(projectTrigger + 1))} />
                )}
            </div>

            <div className='grid gap-[5px] items-start mt-[10px] overflow-y-auto max-h-[150px]'>
                {projects.length > 0 ? (
                    [...projects]
                        .sort((a, b) => a.title.localeCompare(b.title))
                        .map((item, i) => (
                            <Link
                                key={i}
                                to={`/project/${item._id}`}
                                onClick={() => {
                                    onClick()
                                }}
                                className={`flex items-center justify-between gap-[10px] px-[5px] py-[3px] ${(!pathname.includes("/task") ? pathname === `/project/${item._id}` : item._id === selectedTaskData?.projectId) && "bg-active rounded-full"}`}
                            >
                                <div className='flex items-center justify-start gap-[10px]'>
                                    <div className="relative uppercase min-w-[30px] h-[30px] border rounded-full flex justify-center items-center bg-default text-primary text-[12px]">
                                        {getInitialLetter(item.title)}
                                    </div>
                                    <div className={`break-words text-[12px] text-left break-all capitalize w-full ${(pathname.includes("/task") || pathname.includes("/project")) && activeProject._id === item._id ? "text-primary" : "text-default"}`}>{item.title}</div>
                                </div>
                            </Link>
                        ))
                ) : (
                    <p className='text-default text-[12px] text-center'>No Project!</p>
                )}
            </div>

        </div>

    )
}

export default Projects;