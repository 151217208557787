import React, { useState, useEffect } from 'react';
import { DeleteRequest, PutRequest } from '../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import DeleteConfirmation from './deleteConfirmation';
import ReactModal from '.';
import Loader from '../components/custom/loader';
import Button from '../components/custom/button';
import TextInput from '../components/custom/textInput';
import Label from '../components/custom/label';
import Error from '../components/custom/error';
import TaskSocket from '../socket/task-socket';
import { projectActions } from '../store/project-slice';

const ProjectAction = ({ icon, className = "", trigger = () => { } }) => {
    const dispatch = useDispatch()
    const { assignees } = useSelector((state) => state.task);
    const { projectTrigger, activeProject } = useSelector((state) => state.project);
    const { accountInfo } = useSelector((state) => state.account);

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState(activeProject?.title);
    const [error, setError] = useState("");
    const [disabled, setDisabled] = useState(false);

    const isAdmin = accountInfo?.data.role?.title?.toLowerCase() === "admin";
    
    useEffect(() => {
        setTitle(activeProject?.title || "");
    }, [activeProject?.title]);

    const nameHandler = (e) => {
        const { value } = e.target;
        const cleanedValue = value.replace(/[^A-Za-z\s]/g, '');
        setTitle(cleanedValue)
        setError("");
    };

    function closeHandler() {
        setOpen(false);
        setError("");
        setDisabled(false);
        setTitle(activeProject?.title)
    }
    function deleteHandler() {
        setDisabled(true);
        DeleteRequest(`${process.env.REACT_APP_URL}/project/${activeProject?._id}?userId=${accountInfo?.data._id}`).then(response => {
            trigger();
            closeHandler();
            dispatch(projectActions.setProjectTrigger(projectTrigger + 1))
            if (activeProject.createdBy === accountInfo?.data._id) {
                TaskSocket.emitProjectUpdate(activeProject.admin, assignees)
            } else {
                TaskSocket.emitProjectUpdate(activeProject.createdBy, assignees)
            }
            // TaskSocket.emitProjectUpdate(userId, assignees)
        }).catch(err => {
            console.log(err);
            setDisabled(false);
        })
    }
    function updateHandler() {
        setError("");
        setDisabled(true);
        if (!title.trim()) {
            setError("Field must not be empty!");
            setDisabled(false);
        }
        else {
            PutRequest(`${process.env.REACT_APP_URL}/project/${activeProject._id}?userId=${accountInfo?.data._id}`, { title: title }).then(response => {
                dispatch(projectActions.setProjectTrigger(projectTrigger + 1))
                if (activeProject.createdBy === accountInfo?.data._id) {
                    TaskSocket.emitProjectUpdate(activeProject.admin, assignees)
                } else {
                    TaskSocket.emitProjectUpdate(activeProject.createdBy, assignees)
                }
                closeHandler();
            }).catch(err => {
                console.log(err);
                setDisabled(false);
            })
        }
    }
    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            updateHandler()
        }
    };
    const permissions = accountInfo?.permissions;

    return (
        <>
            <button onClick={() => setOpen(true)} className={className}>{icon}</button>
            <ReactModal open={open} close={closeHandler} maxWidth="500px">
                <div className='w-full'>
                    {disabled && <Loader />}
                    <h5 className='mb-[20px]'>Edit Project</h5>
                    <Error message={error} />
                    <div className="w-full">
                        <Label title='Title' />
                        <TextInput
                            value={title}
                            name="title"
                            onChange={nameHandler}
                            maxLength={30}
                            onKeyDown={handleKeyDown}
                            disabled={!permissions?.updateProject && !isAdmin}
                        />
                    </div>
                    <div className='flex justify-center gap-[10px] mt-[40px]'>
                        <DeleteConfirmation
                            onClick={(!isAdmin && !permissions?.deleteProject) ? null : deleteHandler}
                            className={`max-w-[120px] ${(!isAdmin && !permissions?.deleteProject) ? 'pointer-events-none' : ''}`}
                            close={() => setOpen(false)}
                            disabled={!isAdmin && !permissions?.deleteProject}
                        />
                        <Button
                            label='Update'
                            onClick={updateHandler}
                            type='secondary'
                            disabled={!isAdmin && !permissions?.updateProject}
                        />

                    </div>
                </div>
            </ReactModal>
        </>
    )
}

export default ProjectAction;