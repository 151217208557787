import React from 'react'
import { formatDateForCompare } from '../../utils/formatTimestamp';

const PlanTag = ({expiryDate}) => {
    let currentdate = formatDateForCompare(new Date());

    function tagCss(expiryDate) {
        let className;
        if (currentdate > formatDateForCompare(expiryDate)){
            className = "bg-[#ffe4e1] text-error";
        }else if(currentdate === formatDateForCompare(expiryDate)){
            className = "bg-[#ffffe0] text-[#f5c71a]";
        }else{
            className = "bg-[#addfad] text-success";
        }
        return className;
    }

    return (
        <div className={`rotate-[40deg] w-[147px] text-center absolute top-[21px] right-[-36px] z-[1] shadow-md py-[3px] ${tagCss(expiryDate)}`}>
            {currentdate > formatDateForCompare(expiryDate)
                ? "Expired"
                : currentdate === formatDateForCompare(expiryDate)
                    ? "Expiring Today"
                    : "Active"
            }
        </div>
    )
}

export default PlanTag;