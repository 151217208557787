import React from 'react';
import { formatDateAndTime } from '../../utils/formatTimestamp';

const NextPlan = ({data}) => {
    function activateNow(){
        alert("under development")
    }
    return (
        <div className='p-[30px] border w-full shadow-md inline-flex flex-col relative bg-white mt-[20px] lg:mt-[30px] overflow-hidden'>
            <div className='mt-[10px] flex justify-between items-center'>
                <h4>Upcoming Plan</h4>
                <button className='text-link cursor-pointer' onClick={activateNow}>Activate Now</button>
            </div>
            <div className='flex justify-between items-center py-[10px] gap-[30px] mt-[10px]'>
                <span>Plan</span>
                <span className='max-w-[400px] text-right'>{data.plan.title || "---"}</span>
            </div>
            <div className='flex justify-between items-center py-[10px] gap-[30px]'>
                <span>Will Start On</span>
                <span className='max-w-[400px] text-right'>{formatDateAndTime(data.startedOn) || "---"}</span>
            </div>
        </div>
    )
}

export default NextPlan;