import React from 'react';
import { formatDateAndTime } from '../../utils/formatTimestamp';
import { useSelector } from 'react-redux';
import PlanTag from '../shared/planTag';

const CurrentPlan = () => {
    const { currentPlan } = useSelector((state) => state.subscription);

    return (
        <div className='p-[30px] border w-full shadow-md inline-flex flex-col relative bg-white mt-[20px] lg:mt-[30px] overflow-hidden'>
            <PlanTag expiryDate={currentPlan.expiryOn} />
            <h4>Current Plan</h4>
            <div className='flex justify-between items-center py-[10px] gap-[30px] mt-[10px]'>
                <span>Plan</span>
                <span className='max-w-[400px] text-right'>{currentPlan.plan.title || "---"}</span>
            </div>
            <div className='flex justify-between items-center py-[10px] gap-[30px]'>
                <span>Started Date</span>
                <span className='max-w-[400px] text-right'>{formatDateAndTime(currentPlan.startedOn) || "---"}</span>
            </div>
            <div className='flex justify-between items-center py-[10px] gap-[30px]'>
                <span>Expiry Date</span>
                <span className='max-w-[400px] text-right'>{formatDateAndTime(currentPlan.expiryOn) || "---"}</span>
            </div>
        </div>
    )
}

export default CurrentPlan;