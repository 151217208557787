import React, { useEffect, useState } from "react";
import ReactModal from ".";
import Error from "../components/custom/error";
import Pagination from "../components/custom/pagination";
import Dropdown from "../components/custom/dropdown";
import { GetRequest } from "../utils/request";

const BulkEmployeeUpload = ({ open = false, setOpenBulkUpload = () => { }, data = [], departments = [], setFile = () => { } }) => {

    function closeHandler() {
        setOpenBulkUpload(false)
        setFile(prevFile =>
            prevFile.map(item => ({
                ...item,
                department: "",
                role: ""
            }))
        );
    }
    return (
        <>
            <ReactModal open={open} close={closeHandler} maxWidth="1200px" heading="Manage Employees" >
                <div>
                    <Pagination
                        itemsPerPage={10}
                        data={data}
                        renderComponent={(currentItems) => (
                            <EmployeeData
                                data={currentItems}
                                departments={departments}
                                setFile={setFile}
                                setOpenBulkUpload={setOpenBulkUpload}
                            />
                        )}
                    />
                </div>
            </ReactModal>
        </>
    )
}

function EmployeeData({ data, departments, setFile, setOpenBulkUpload }) {
    const [selectedDepartments, setSelectedDepartments] = useState({});
    const [selectedRoles, setSelectedRoles] = useState({});
    const [roles, setRoles] = useState({});
    const [error, setError] = useState("");

    useEffect(() => {
        const deptExist = data.filter((item) => item?.department && item.department !== "")
        if (deptExist) {
            deptExist.forEach((item, index) => {
                GetRequest(`${process.env.REACT_APP_URL}/department/roles/${item.department}`)
                    .then(response => {
                        setRoles(prev => ({
                            ...prev,
                            [index]: response.data
                        }));
                    })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDepartmentChange = (department, rowIndex) => {
        setSelectedDepartments(prev => ({
            ...prev,
            [rowIndex]: department
        }));

        if (department?._id) {
            GetRequest(`${process.env.REACT_APP_URL}/department/roles/${department._id}`)
                .then(response => {
                    setRoles(prev => ({
                        ...prev,
                        [rowIndex]: response.data
                    }));
                    setSelectedRoles(prev => ({
                        ...prev,
                        [rowIndex]: null
                    }));
                    setFile(prevFile => {
                        const updatedFile = [...prevFile];
                        updatedFile[rowIndex].department = department._id;
                        updatedFile[rowIndex].role = "";
                        return updatedFile;
                    });

                })
                .catch(error => {
                    console.error("fetch roles error >", error);
                    setRoles(prev => ({
                        ...prev,
                        [rowIndex]: []
                    }));
                });
        } else {
            setRoles(prev => ({
                ...prev,
                [rowIndex]: []
            }));
        }
    };

    const handleRoleChange = (role, rowIndex) => {
        setSelectedRoles(prev => ({
            ...prev,
            [rowIndex]: role
        }));
        setFile(prevFile => {
            const updatedFile = [...prevFile];
            updatedFile[rowIndex].role = role._id;
            return updatedFile;
        });
    };

    const handleSave = () => {

        const missingDepartment = data.some((item) => !item.department);
        const missingRole = data.some((item) => !item.role);
        const datePattern = /^\d{2}[\/-]\d{2}[\/-]\d{4}$/;
        const invalidBirthday = data.some((item) => !datePattern.test(item.birthday));
        const invalidJoiningDate = data.some((item) => !datePattern.test(item.joiningDate));
        if (invalidBirthday) {
            setError("Each employee's birthday must be in the format DD/MM/YYYY (e.g., 21/07/2024).");
        } else if (invalidJoiningDate) {
            setError("Each employee's joining date must be in the format DD/MM/YYYY (e.g., 21/07/2024).");
        } else if (missingDepartment) {
            setError("Each employee must have a department selected.");
        } else if (missingRole) {
            setError("Each employee must have a role selected.");
        } else {
            setError("");
            setOpenBulkUpload(false)
        }
    };

    return (
        <>
            <div className="flex justify-end">
                <button className='w-[150px] border h-[40px] bg-strawberry text-white rounded-full' onClick={handleSave} >Done</button>
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg max-md:pb-[10px] scrollbar-h-5px">
            {error && <Error message={error} />}
            <table className='mt-[20px] relative w-full'>
                <thead>
                    <tr className='bg-[#dcdcdc] py-[120px]'>
                        {columns.map((col, i) => (
                            <td
                                key={i}
                                style={{ width: col.width, textAlign: col.textAlign }}
                                className='!font-bold !text-[14px] text-left px-[10px] py-[10px] whitespace-nowrap'
                            >{col.header}</td>
                        ))}
                        <td className='text-left whitespace-nowrap'></td>
                    </tr>
                </thead>
                {data.length > 0 ? (
                    <>
                        <tbody>
                            {data.map((item, i) => (
                                <tr key={i} className={`bg-[#f8f8ff] border-b`}>
                                    <td className='text-left text-[14px] px-[10px] py-[5px]'>{i + 1 || "----"}</td>
                                    <td className='text-left text-[14px] px-[10px] py-[5px]'>{item?.employeeID || "----"}</td>
                                    <td className='text-left text-[14px] px-[10px] py-[5px]'>{item?.fullName || "----"}</td>
                                    <td className='text-left text-[14px] px-[10px] py-[5px]'>{item?.email}</td>
                                    <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>
                                        {item?.birthday}
                                    </td>
                                    <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>
                                        {item?.joiningDate}
                                    </td>
                                    <td className='text-left text-[14px] px-[10px] py-[5px]'>{item?.gender}</td>
                                    <td className='text-left text-[14px] px-[10px] py-[5px]'>{item?.password}</td>
                                    <td className='text-left text-[14px] px-[10px] py-[5px]'>{item?.officeLocation}</td>
                                    <td className="text-left text-[14px] px-[10px] py-[5px]">
                                        <Dropdown
                                            value={
                                                selectedDepartments[i]?.title ||
                                                (departments?.find(dep => dep._id === item.department)?.title || "")
                                            } onChange={(option) => handleDepartmentChange(option, i)}
                                            options={departments}
                                            className='mt-[5px]'
                                        />
                                    </td>
                                    <td className="text-left text-[14px] px-[10px] py-[5px]">
                                        <Dropdown
                                            value={
                                                selectedRoles[i]?.title ||
                                                (Object.keys(roles).length > 0 && roles[i]?.find(role => role._id === item.role)?.title) ||
                                                ""
                                            } onChange={(option) => handleRoleChange(option, i)}
                                            options={roles[i] || []}
                                            className='mt-[5px]'
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </>
                ) : (
                    <p className='text-center w-full absolute top-[70px]'>No Data Available!</p>
                )}
            </table>
            </div>
        </>
    );
}

const columns = [
    {
        header: 'S.No.',
        textAlign: "left"
    },
    {
        header: "Employee ID",

        textAlign: "left"
    },
    {
        header: 'Full Name',

        textAlign: "left"
    },
    {
        header: 'Email',

        textAlign: "left"
    },
    {
        header: 'Birthday',

        textAlign: "left"
    },
    {
        header: 'Date of Joining',

        textAlign: "left"
    },
    {
        header: 'Gender',

        textAlign: "left"
    },
    {
        header: 'Password',
   
        textAlign: "left"
    },
    {
        header: "Location",

        textAlign: "left"
    },
    {
        header: 'Department',
        width: "200px",
        textAlign: "left"
    }, {
        header: 'Designation',
        width: "200px",
        textAlign: "left"
    },
];

export default BulkEmployeeUpload;