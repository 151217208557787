import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetRequest, PutRequest } from "../../utils/request";
import Avatar from "../custom/avatar";
import { chatActions } from "../../store/chat-slice";
import ChatSocket from "../../socket/chat-socket";

const BlockList = () => {
    const dispatch = useDispatch()
    const { accountInfo } = useSelector((state) => state.account);
    const {triggerChat} = useSelector((state) => state.chat);
    const [blockList, setBlockList] = useState([])
    const [triggerList, setTriggerList] = useState(0)
    
    useEffect(() => {
        if (accountInfo) {
            GetRequest(`${process.env.REACT_APP_URL}/user/block/${accountInfo?.data?._id}`).then((response) => {
                setBlockList(response.data)
            }).catch((error) => {
                console.error("Error fetching user details:", error);
            });
        }
    }, [accountInfo, triggerList])

    function blockHandler(id) {
        PutRequest(`${process.env.REACT_APP_URL}/friend/block/${id}`, {
            blockedBy: accountInfo?.data?._id
        }).then((response) => {
            setTriggerList(prev => prev + 1)
            dispatch(chatActions.setTriggerChat(triggerChat + 1))
            ChatSocket.emitBlockUser(id, response.data, accountInfo?.data?._id)
        }).catch((error) => {
            console.log(error, "error blocking user");
        });
    }

    return (
        <div className='mb-[30px] max-w-[700px]'>
            <h5 className="mb-[20px] border-b">Blocked Users</h5>
            {blockList.length > 0 ? (
                blockList.map((item) =>
                    <div className='flex items-center justify-between mb-[15px] '>
                        <div className='flex items-center gap-[10px]'>
                            <Avatar
                                src={item?.participants[0]?.avatar}
                            />
                            <div className='grid'>
                                <span className='text-[14px] font-semibold'>{item?.participants[0]?.fullName}</span>
                                <span className='text-[14px] text-[#aaa]'>{item?.participants[0]?.email}</span>
                            </div>
                        </div>
                        <button onClick={() => blockHandler(item._id)} className='border px-[10px] bg-white'>
                            Unblock
                        </button>
                    </div>
                )) : (
                    <div>
                        <p>Looks like your block list is taking a vacation—no one’s been blocked!</p>
                    </div>
                )}
        </div>
    )
}

export default BlockList;