import React from 'react'
import UpdateAccount from '../../popups/updateAccount';
import { formatDate } from '../../utils/formatTimestamp';
import { useSelector } from 'react-redux';

const MyInfo = () => {
    const { accountInfo, companyData } = useSelector((state) => state.account);
    return (
        <div>
            <UpdateAccount data={accountInfo?.data} />
            {accountInfo?.data?.role.title?.toLowerCase() !== "admin" && (
                <>
                <div className='flex justify-between items-center py-[10px] gap-[30px] mt-[10px]'>
                    <span>Employee ID</span>
                    <span className='max-w-[400px] text-right capitalize'>{accountInfo?.data?.employeeID || "---"}</span>
                </div> 
                <hr />
                </>
            )}
            <div className='flex justify-between items-center py-[10px] gap-[30px]'>
                <span>Full Name</span>
                <span className='max-w-[400px] text-right capitalize'>{accountInfo?.data?.fullName || "---"}</span>
            </div>
            <hr />
            <div className='flex justify-between items-center py-[10px] gap-[30px]'>
                <span>Birthday</span>
                <span className='max-w-[400px] text-right'>{accountInfo?.data?.birthday ? formatDate(accountInfo.data.birthday) : "---"}</span>
            </div>
            <hr />
            <div className='flex justify-between items-center py-[10px] gap-[30px]'>
                <span>Email</span>
                <span className='max-w-[400px] text-right'>{accountInfo?.data?.email}</span>
            </div>
            <hr />
            <div className='flex justify-between items-center py-[10px] gap-[30px]'>
                <span>Phone Number</span>
                <span className='max-w-[400px] text-right'>{accountInfo?.data?.mobile || "---"}</span>
            </div>
            <hr />
            <div className='flex justify-between items-center py-[10px] gap-[30px]'>
                <span>Gender</span>
                <span className='max-w-[400px] text-right capitalize'>{accountInfo?.data?.gender || "not mentioned"}</span>
            </div>
            {accountInfo?.data?.role.title?.toLowerCase() !== "admin" && (
                <>
                    <hr />
                    <div className='flex justify-between items-center py-[10px] gap-[30px]'>
                        <span>Department</span>
                        <span className='max-w-[400px] text-right capitalize'>{companyData?.department || "---"}</span>
                    </div>
                    <hr />
                    <div className='flex justify-between items-center py-[10px] gap-[30px]'>
                        <span>Designation</span>
                        <span className='max-w-[400px] text-right capitalize'>{accountInfo?.data?.role.title || "---"}</span>
                    </div>
                    <hr />
                    <div className='flex justify-between items-center py-[10px] gap-[30px]'>
                        <span>Joining Date</span>
                        <span className='max-w-[400px] text-right'>{accountInfo?.data?.joiningDate ? formatDate(accountInfo.data.joiningDate) : "---"}</span>
                    </div>
                    <hr />
                    <div className='flex justify-between items-center py-[10px] gap-[30px]'>
                        <span>Office Location</span>
                        <span className='max-w-[400px] text-right capitalize'>{accountInfo?.data?.officeLocation || "---"}</span>
                    </div>
                </>
            )}
        </div>
    )
}

export default MyInfo;