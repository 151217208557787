import React from 'react'

const PlanSelection = ({
    plans=[], 
    formInput, 
    setFormInput=()=>{},
    onNextClick=()=>{}
}) => {
    return (
        <div>
            <label className='text-[12px] font-medium pb-[5px]'>Subscription</label>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-[10px] mt-[10px]'>
                {plans.length > 0 && plans.map((item, i) => (
                    <button
                        key={i}
                        onClick={() => setFormInput(prevState => ({ ...prevState, plan: item._id }))}
                        className={`border p-[10px] flex justify-between items-center ${formInput.plan === item._id && "border-black"}`}
                    >
                        <span className='font-semibold capitalize'>{item.title}</span>
                        <span>{item.price === 0 ? "3 days trial" : `INR ${item.price} / ${item.days} days`}</span>
                    </button>
                ))}
            </div>

            <div className='flex justify-end items-center mt-[30px]'>
                <button
                    className='w-full max-w-[100px] bg-primary text-default py-[5px]'
                    onClick={onNextClick}
                >Next</button>
            </div>
        </div>
    )
}

export default PlanSelection;