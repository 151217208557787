import React from 'react'
import Avatar from '../custom/avatar';
import { useSelector } from 'react-redux';
import { formatDate } from '../../utils/formatTimestamp';
import isAnniversaryToday from '../../utils/isBirthdayToday';

const Anniversaries = () => {
    const { anniversaries } = useSelector((state) => state?.account);

    return (
        <div className='p-[30px] border w-full shadow-md relative bg-white mt-[20px] lg:mt-[30px] overflow-hidden overflow-y-auto'>
            <div className='flex  gap-2 items-center'>
                <h4 className='mb-[5px]'>Upcoming Work Anniversaries</h4>
                <p className='text-[32px]'>🏆</p>
            </div>
            <div className='overflow-hidden overflow-y-auto py-[5px] px-[2px]'>
                <div className='grid gap-[5px] items-start mt-[10px] overflow-y-auto max-h-[150px]'>
                    {anniversaries?.map((item, i) => (
                        <div key={i} className=' flex justify-between mt-3 px-[4px]'>
                            <div className='relative flex justify-center items-center gap-2'>
                                {isAnniversaryToday(item?.joiningDate) && (
                                    <div className='absolute top-[1px] left-[-6px] z-[1] animate-pulse text-[18px]'>
                                        🌟
                                    </div>
                                )}
                                <Avatar
                                    src={item.avatar}
                                    size={42}
                                    className={`${isAnniversaryToday(item?.joiningDate) ? 'border-[#F4C2C2] border-[2px]' : ''}`}
                                />
                                <div>
                                    <p className='text-[13px] font-semibold'>{item?.fullName}</p>
                                    <div className='text-[11px] text-[#8a8a8a]'>
                                        <p>{item?.role?.title}</p>
                                        <p>({item?.role?.departmentId?.title})</p>
                                    </div>
                                </div>
                            </div>
                            <div className='text-right'>
                                {isAnniversaryToday(item?.joiningDate) ?
                                    <p className='text-[13px] text-success'>Today</p>
                                    :
                                    <p className='text-[13px]'>{formatDate(item?.joiningDate)}</p>

                                }
                                <p>(Celebrating {item?.yearsCompleted} {item?.yearsCompleted === 1 ? 'Year' : 'Years'})</p>

                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Anniversaries;