import React from 'react'
import { FaChalkboardUser, FaPaste } from "react-icons/fa6";
import { useSelector } from 'react-redux';
import { formatDate } from '../../utils/formatTimestamp';
import randomColors from "../../data/randomColor.json";

const Description = () => {
    const { activeProject, activeProjectMilestones } = useSelector((state) => state.project);
    const createdAt = activeProject?.createdAt || null;
    return (
        <div className='mt-[20px] mb-[30px]'>
            <div className='grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:gap-[30px] max-md:gap-[15px]'>
                <div className='w-full'>
                    <div className='flex items-center px-5 py-6 bg-[#5d87ff20] rounded-[15px] shadow-sm'>
                        <div className='p-3 bg-indigo-600 bg-opacity-75 rounded-full'>
                            <FaChalkboardUser className="w-8 h-8 text-white" />
                        </div>
                        <div className='mx-5'>
                            <h3 className="text-[26px] font-semibold text-[#000]">Created On</h3>
                            <h4 className="text-[18px] font-semibold text-gray-500">{formatDate(createdAt)}</h4>
                        </div>
                    </div>
                </div>
                {activeProjectMilestones.map((item, i) => (
                    <div key={i} className='w-full'>
                        <div className='flex items-center px-5 py-6 bg-[#fdede8] rounded-[15px] shadow-sm' style={{ backgroundColor: randomColors[i]}}>
                            <div className='p-3 bg-orange-600 bg-opacity-75 rounded-full'>
                                <FaPaste className="w-8 h-8 text-white" />
                            </div>
                            <div className='mx-5'>
                                <h3 className="text-[26px] font-semibold text-[#000]">{item.tasks?.length || 0}</h3>
                                <h4 className="text-[18px] font-semibold text-gray-500">{item.title}</h4>
                            </div>
                        </div>
                    </div>
                ))}
                {/* <div className='w-full'>
                    <div className='flex items-center px-5 py-6 bg-[#e6fffa] rounded-[15px] shadow-sm'>
                        <div className='p-3 bg-[#13deb9] bg-opacity-75 rounded-full'>
                            <FaLaptopFile className="w-8 h-8 text-white" />
                        </div>
                        <div className='mx-5'>
                            <h3 className="text-[26px] font-semibold text-[#000]">25</h3>
                            <h4 className="text-[18px] font-semibold text-gray-500">Projects Completed</h4>
                        </div>
                    </div>
                </div>
                <div className='w-full'>
                    <div className='flex items-center px-5 py-6 bg-[#fef5e5] rounded-[15px] shadow-sm'>
                        <div className='p-3 bg-[#ffae1f] bg-opacity-75 rounded-full'>
                            <FaHourglassHalf className="w-8 h-8 text-white" />
                        </div>
                        <div className='mx-5'>
                            <h3 className="text-[26px] font-semibold text-[#000]">8,282</h3>
                            <h4 className="text-[18px] font-semibold text-gray-500">In Progress</h4>
                        </div>
                    </div>
                </div>
                <div className='w-full'>
                    <div className='flex items-center px-5 py-6 bg-[#fff0f5] rounded-[15px] shadow-sm'>
                        <div className='p-3 bg-[#FF4081] bg-opacity-75 rounded-full'>
                            <FaRecycle className="w-8 h-8 text-white" />
                        </div>
                        <div className='mx-5'>
                            <h3 className="text-[26px] font-semibold text-[#000]">8,282</h3>
                            <h4 className="text-[18px] font-semibold text-gray-500">Testing Failed</h4>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Description;