import React from 'react';
import { RiShieldUserLine } from "react-icons/ri";
import { PiGraphFill } from "react-icons/pi";
import { useDispatch } from 'react-redux';
import { uiActions } from '../../store/ui-slice';

const FilterTabs = ({ setActiveTab=()=>{}, ...props }) => {
    const dispatch = useDispatch();
    return (
        <div className="border-b border-gray-200 dark:border-gray-700 mt-[30px]">
            <div className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                {tabs.map((item, i) => (
                    <button 
                        key={i} 
                        onClick={() => {
                            setActiveTab(i)
                            dispatch(uiActions.setPaginationOffset(0));
                            props.setSearchKey("")
                        }} 
                        className={`me-2 inline-flex items-center justify-center p-4 gap-[5px] ${props.activeTab === i && "text-blue-600 border-b-2 border-blue-600"}`}
                    >
                        {item.icon} {item.label}
                    </button>
                ))}
            </div>
        </div>
    )
}

const tabs = [
    {
        label: "Designation",
        icon: <PiGraphFill size={20} />
    },
    {
        label: "Employees",
        icon: <RiShieldUserLine size={20} />
    }
]

export default FilterTabs