import React from 'react'
import { useDispatch } from 'react-redux';
import { GrFormEdit } from "react-icons/gr";
import isBirthdayToday from '../../utils/isBirthdayToday';
import Avatar from '../custom/avatar';
import IconButton from '../custom/iconButton';
import { employeeActions } from '../../store/employee-slice';
import { HiLocationMarker } from "react-icons/hi";

const EmployeeCard = ({data, isAdmin, permissions}) => {
    const dispatch = useDispatch();
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[10px] mt-[30px]">
            {data.map((item, i) => (
                <div key={i} className="group relative w-full bg-white border border-gray-200 rounded-lg shadow p-[20px] flex gap-[30px] items-start overflow-hidden">
                    {isBirthdayToday(item.birthday) && (
                        <BirthdayTag />
                    )}
                    <Avatar
                        src={item.avatar} 
                        alt={item.fullName} 
                        size={20} 
                        className={`h-[50px] min-w-[50px] border-[2px] ${isBirthdayToday(item.birthday) ? "!border-error" : item.gender === "female" ? "!border-[#FF69B4] border-[2px]" : item.gender === "male" ? "!border-[#6ca0dc]" : "border-[#aaa]"}`} 
                        noOnline
                        onClick={() => dispatch(employeeActions.setViewEmployee(item)) }
                    />
                    <div>
                        <p className="text-[#aaa] break-all">{item.employeeID}</p>
                        <h6 className="font-medium capitalize">{item.fullName}</h6>
                        <p className="text-black break-all">{item.email}</p>
                        <p className="text-[#aaa] capitalize">{item.role?.departmentId?.title} ({item.role?.title})</p>
                        {item.officeLocation && (
                            <p className="text-black break-all flex items-center capitalize"><HiLocationMarker className='text-error' /> {item.officeLocation}</p>
                        )}
                        {/* <p className="capitalize flex mt-[5px] items-center"> <TbCalendarUser size={15} title='Date of Joining' className='mr-[5px]' /> {item.joiningDate ? formatDate(item.joiningDate) : "---"}</p> */}
                        {/* <p className="capitalize flex mt-[5px] text-[#fc0fc0] items-center"> <LiaBirthdayCakeSolid size={15} className='mr-[5px]' /> {item.birthday ? formatDate(item.birthday) : "---"}</p> */}
                    </div>
                    {isAdmin && (
                        <div className='absolute top-[5px] right-[5px] group-hover:block hidden'>
                            <IconButton
                                icon={<GrFormEdit size={20} color='#000' />}
                                className='bg-white h-[30px] w-[30px] border-none'
                                title="Edit"
                                onClick={() => dispatch(employeeActions.setUpdateEmployee(item))}
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}

function BirthdayTag(){
    return(
        <div className='text-error -rotate-[40deg] bg-[#ffe4e1] px-[21px] absolute top-[10px] left-[-20px] z-[1] shadow-md'>
            Birthday
        </div>
    )
}

export default EmployeeCard;