import React, { useState } from 'react'
import Label from '../custom/label';
import TextInput from '../custom/textInput';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';

const Password = ({
    formInput, 
    setFormInput=()=>{},
    setError=()=>{},
    onNextClick=()=>{}
}) => {
    const [show, setShow] = useState({
        password: false,
        confirmPassword: false
    });

    //PASSWORD INPUT HANDLER
    const passwordHandler = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(" ", '');
        setFormInput((prevState) => ({
            ...prevState,
            [name]: cleanedValue
        }));
        setError("");
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            onNextClick()
        }
    };

    return (
        <div>
            <div className='relative'>
                <Label
                    title='Password'
                    htmlFor="password"
                    isImportant
                />
                <TextInput
                    name="password"
                    value={formInput.password}
                    type={show.password ? "text" : "password"}
                    onChange={passwordHandler}
                    onKeyDown={handleKeyDown}
                />
                {show.password ? (
                    <button onClick={() => setShow(prevState => ({ ...prevState, password: false }))} className='absolute right-[10px] top-[68%] translate-y-[-50%]' >
                        <FaRegEyeSlash size={15} />
                    </button>
                ) : (
                    <button onClick={() => setShow(prevState => ({ ...prevState, password: true }))} className='absolute right-[10px] top-[68%] translate-y-[-50%]' >
                        <FaRegEye size={15} />
                    </button>
                )}
            </div>
            <div className='relative'>
                <Label
                    title='Confirm Password'
                    htmlFor="confirm-password"
                    isImportant
                />
                <TextInput
                    name="confirmPassword"
                    value={formInput.confirmPassword}
                    type={show.confirmPassword ? "text" : "password"}
                    onChange={passwordHandler}
                    onKeyDown={handleKeyDown}
                />
                {show.confirmPassword ? (
                    <button onClick={() => setShow(prevState => ({ ...prevState, confirmPassword: false }))} className='absolute right-[10px] top-[68%] translate-y-[-50%]' >
                        <FaRegEyeSlash size={15} />
                    </button>
                ) : (
                    <button onClick={() => setShow(prevState => ({ ...prevState, confirmPassword: true }))} className='absolute right-[10px] top-[68%] translate-y-[-50%]' >
                        <FaRegEye size={15} />
                    </button>
                )}
            </div>
            <div className='flex justify-end items-center mt-[30px]'>
                <button
                    className='w-full max-w-[100px] bg-success text-default py-[5px]'
                    onClick={onNextClick}
                >Complete</button>
            </div>
        </div>
    )
}

export default Password;