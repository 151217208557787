import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    projects: [],
    activeProject: null,
    activeProjectMilestones: [],
    layout: "kanban",
    projectTrigger: 0,
    projectPermissions: null,
    selectedMilestone: null
}

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setProjects(state, action) {
            state.projects = action.payload;
        },
        setActiveProject(state, action) {
            state.activeProject = action.payload;
        },
        setActiveProjectMilestones(state, action) {
            state.activeProjectMilestones = action.payload;
        },
        setLayout(state, action) {
            state.layout = action.payload;
        },
        setProjectTrigger(state, action) {
            state.projectTrigger = action.payload;
        },
        setSelectedMilestone(state, action) {
            state.selectedMilestone = action.payload;
        },
        setProjectPermissions(state, action) {
            state.projectPermissions = action.payload;
        },
    }
});

export const projectActions = projectSlice.actions;

export default projectSlice.reducer;