import React from 'react';

const Button = ({ label="", onClick=()=>{}, className="", type="primary", ...props }) => {
    return (
        type === 'secondary' ? (
            <button 
                onClick={onClick} 
                className={`w-full inline-flex justify-center items-center gap-[5px] text-[#fff] h-[40px] text-[16px] leading-[27px] text-center rounded-full ${className} ${props.disabled ? "bg-[#aaa] pointer-events-none" : "bg-strawberry"}`}
                {...props}
            >
                {label} 
            </button>
        ):(
            type === 'outlined' ? (
                <button 
                    onClick={onClick} 
                    className={`w-full inline-flex justify-center items-center gap-[5px] border border-black text-[#fff] h-[40px] text-[16px] leading-[27px] font-medium text-center rounded-full ${className} ${props.disabled && "bg-[#aaa] pointer-events-none"}`}
                    {...props}
                >
                    {label} 
                </button>
            ):(
                <button 
                    onClick={onClick} 
                    className={`w-full inline-flex justify-center items-center gap-[5px] border border-[#594cda] text-[#fff] hover:text-[#594cda] hover:bg-[#fff] h-[40px] text-[16px] leading-[27px] font-medium text-center rounded-full ${className} ${props.disabled ? "bg-[#aaa] pointer-events-none" : "bg-[#594cda]"}`}
                    {...props}
                >
                    {label} 
                </button>
            )
        )
    )
}

export default Button;