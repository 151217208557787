import React, { useState } from 'react'
import kitty from "../../assets/icons/kitty.svg";
import { PostRequest } from '../../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from '../custom/textInput';
import Dropdown from '../custom/dropdown';
import Button from '../custom/button';
import Error from '../custom/error';
import { uiActions } from '../../store/ui-slice';

const CreateEvent = ({event, setTrigger=()=>{}, setEvent=()=>{} }) => {
    const dispatch = useDispatch();
    const { accountInfo } = useSelector((state) => state?.account);
    const [error, setError] = useState("");
    const { departments } = useSelector((state) => state.employee);

    // Creating a new event
    const createEventHandler = () => {
        if (!event?.eventName.trim() || !event?.eventTime) {
            setError("Field must not be empty");
        } else {
            PostRequest(`${process.env.REACT_APP_URL}/event/${accountInfo?.data?._id}?departmentid=${event?.selectedDepartmentId}`, {
                label: event.eventName,
                eventDate: event.selectedDate,
                eventTime: event.eventTime,
            }).then(() => {
                dispatch(uiActions.setToastify({
                    isSuccess: true,
                    message: "Event added successfully!"
                }))
                setEvent({
                    selectedDepartments: { title: "All" },
                    selectedDepartmentId: null,
                    selectedDate: null,
                    eventName: "",
                    eventTime: ""
                });
                setError(""); 
                setTrigger(prev => prev + 1);
                dispatch(uiActions.setPaginationOffset(0))
            }).catch((error) => {
                console.log(error, "add event error");
                dispatch(uiActions.setToastify({
                    isSuccess: false,
                    message: "Unable to add event!"
                }))
            });
        }
    };

    const handleCreateEventKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            createEventHandler()
        }
    };

    return (
        <div>
            <div className='flex gap-[10px] item-center'>
                <img
                    src={kitty}
                    alt="kitty"
                    className='max-w-[100px] w-full h-auto'
                />
                <h2 className='my-auto'>Create Event</h2>
            </div>
            {event.selectedDate ? (
                <div className='mt-[30px] mx-auto max-w-[700px] w-full'>
                    <div>
                        {error && <Error message={error} />}
                        <p>Selected Date: {event.selectedDate.toDateString()}</p>
                        <div className='flex max-lg:flex-col gap-[10px] mt-[10px]'>
                            <TextInput
                                type="text"
                                placeholder="Event Name"
                                value={event.eventName}
                                onChange={(e) => {
                                    setEvent(prevEvent => ({ ...prevEvent, eventName: e.target.value }));
                                    setError(""); 
                                }}
                                className='max-md:w-full w-[80%]'
                                maxLength={30}
                                onKeyDown={handleCreateEventKeyDown}
                            />
                            <TextInput
                                type="time"
                                placeholder="Event Time"
                                value={event.eventTime}
                                onChange={(e) => {
                                    setEvent(prevEvent => ({ ...prevEvent, eventTime: e.target.value }));
                                    setError(""); 
                                }}
                                className='w-full lg:w-[700px]'
                                maxLength={30}
                            />
                            <Dropdown                                    
                                value={event?.selectedDepartments?.title}
                                onChange={(option) => {
                                    setEvent(prevEvent => ({
                                        ...prevEvent,
                                        selectedDepartments: option,
                                        selectedDepartmentId: option?.title === "All" ? "" : option?._id
                                    }));
                                }}
                                options={[{ title: "All" }, ...departments]}
                                className='bg-[#f9ffe3] w-full lg:w-[700px]'
                            />
                            <Button
                                label='Add Event'
                                onClick={createEventHandler}
                                type='secondary'
                                className='w-[10%] max-w-[200px]'
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <p className='mt-[30px] text-center'>---Select Date to Add Event---</p>
            )}
        </div>
    )
}

export default CreateEvent;