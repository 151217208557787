import React from 'react'
import Label from '../custom/label';
import TextInput from '../custom/textInput';

const Verification = ({
    setError = () => { },
    onNextClick = () => { },
    setOtp = () => { },
    otp,
    setPage,
    showResendOtpMessage,
    setShowResendOtpMessage = () => { }
}) => {

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            onNextClick()
        }
    };
    return (
        <div>
            <div className="mb-[10px]">
                <Label
                    title="Enter OTP"
                    htmlFor='otp'
                    className='w-full max-w-[70px]'
                    isImportant
                />
                <TextInput
                    id="otp"
                    value={otp}
                    placeholder='OTP'
                    onChange={(e) => {
                        const value = e.target.value;
                        if (!isNaN(value) || value === "") {
                            setOtp(value);
                            setError("");
                        }
                    }}
                    onKeyDown={handleKeyDown}
                />
                {showResendOtpMessage && (
                    <p className='text-right underline text-[#e50914] cursor-pointer' onClick={() => {
                        setPage(1)
                        setShowResendOtpMessage("")
                    }
                    }>
                        Didn't receive OTP
                    </p>
                )}            
            </div>
            <div className='flex justify-end items-center mt-[30px]'>
                {/* <button
                    className='w-full max-w-[100px] bg-primary text-default py-[5px]'
                    onClick={() => onButtonClick("one")}
                >Back</button> */}
                <button
                    className={`w-full max-w-[100px] text-default py-[5px] bg-primary`}
                    onClick={onNextClick}
                >Verify</button>
            </div>
        </div>
    )
}

export default Verification;