import React, { useState } from 'react'
import EmailVerification from '../../popups/emailVerification';

const EmailVerified = () => {
    const [open, setOpen] = useState(false);
    return (
        <div className='p-[30px] border w-full shadow-md relative bg-[#fff0f0] mt-[20px] lg:mt-[30px]'>
            <EmailVerification open={open} setOpen={setOpen} />
            <span className='text-error'>Needs Action</span>
            <h4 className='mb-[5px] text-error'>Email is not verified!</h4>
            <button className='text-link' onClick={() => setOpen(true)}>Verify Now!</button>
        </div>
    )
}

export default EmailVerified;