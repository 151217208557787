import React, { useState, useEffect } from 'react';
import Layout from "../../layout/superAdmin";
import { GetRequest } from '../../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateAndTime } from "../../utils/formatTimestamp"
import Dropdown from '../../components/custom/dropdown';
import Pagination from '../../components/custom/pagination';
import { uiActions } from '../../store/ui-slice';

const Logs = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const { accountInfo } = useSelector((state) => state?.account);
    const [searchKey, setSearchKey] = useState("all");
    
    useEffect(() => {
        if (accountInfo) {
            GetRequest(`${process.env.REACT_APP_URL}/superadmin/logs/${accountInfo.data._id}?type=${searchKey}`).then(response => {
                setData(response.data)
                dispatch(uiActions.setPaginationOffset(0));
            }).catch((error) => {
                console.log("logs error >", error);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo, searchKey]);
    return (
        <Layout>
            <div className='pt-[30px] lg:pt-[50px] pb-[50px]'>
                <div className='flex flex-col md:flex-row justify-between items-center gap-[30px]'>
                    <h3>Logs</h3>
                    <div className='flex items-center gap-[5px]'>
                        <span>Category:</span>
                        <Dropdown
                            value={searchKey}
                            onChange={(option) => {
                                setSearchKey(option?.title)
                            }}
                            options={[{ title: "all" }, ...keys]}
                            className='bg-[#f9ffe3] !w-[150px]'
                        />
                    </div>
                </div>
                <Pagination
                    itemsPerPage={20}
                    data={data}
                    type='box'
                    position='right'
                    renderComponent={(currentItems) => (
                        <LogsData data={currentItems} />
                    )}
                />
            </div>
        </Layout>
    )
}

function LogsData({ data }) {
    return (
        <table className='mt-[20px] relative'>
            <thead>
                <tr className='bg-[#dcdcdc] py-[120px]'>
                    {columns.map((col, i) => (
                        <td
                            key={i}
                            style={{ width: col.width, textAlign: col.textAlign }}
                            className='!font-bold !text-[14px] text-left px-[10px] py-[10px]'
                        >{col.header}</td>
                    ))}
                    <td className='text-left'></td>
                </tr>
            </thead>
            {data.length > 0 ? (
                <tbody>
                    {data?.map((item, i) => (
                        <tr key={i} className={`bg-[#f8f8ff] border-b`}>
                            <td className='text-left text-[14px] px-[10px] py-[5px]'>
                                {item.comment && (
                                    <div dangerouslySetInnerHTML={{ __html: item.comment }} className='richtext-comment' />
                                )}
                            </td>
                            <td className='text-left text-[14px] px-[10px] py-[5px]'>{formatDateAndTime(item.createdAt) || "----"}</td>
                            <td className='text-right text-[14px] px-[10px] py-[5px]'>{item.type}</td>
                        </tr>
                    ))}
                </tbody>
            ) : (
                <p className='text-center w-full absolute top-[70px]'>No Logs!</p>
            )}
        </table>
    );
}

const columns = [
    {
        header: 'Activity',
        width: '60%',
        textAlign: "left"
    },
    {
        header: 'Timestamp',
        width: '30%',
        textAlign: "left"
    },
    {
        header: 'Type',
        width: '10%',
        textAlign: "right"
    },
];

const keys = [
    {
        title: "session",
    },
    {
        title: "plan",
    },
]

export default Logs;