import React, { useEffect, useState } from 'react'
import ReactModal from '.';
import TextInput from '../components/custom/textInput';
import { useDispatch, useSelector } from 'react-redux';
import Label from '../components/custom/label';
import Button from '../components/custom/button';
import { FaCheckCircle } from "react-icons/fa";
import { GetRequest } from '../utils/request';
import Loader from '../components/custom/loader';
import Error from '../components/custom/error';
import { accountActions } from '../store/account-slice';

const EmailVerification = ({ open, setOpen=()=>{} }) => {
    const { accountInfo } = useSelector((state) => state.account);
    const [selected, setSelected] = useState(0);
    const [loading, setLoading] = useState(false);
    function closeHandler(){
        setOpen(false)
        setSelected(0)
    }
    const component = [
        <FirstView setSelected={setSelected} setLoading={setLoading} accountInfo={accountInfo?.data} />,
        <SecondView setSelected={setSelected} setLoading={setLoading} accountInfo={accountInfo} />,
        <ThirdView closeHandler={closeHandler} setLoading={setLoading} />
    ]

    return (
        <ReactModal open={open} close={closeHandler} maxWidth="600px" padding='20px' margin='10px' heading='Email Verification' >
            {loading && (
                <Loader />
            )}
            <div>
                <div className={`w-full max-h-[300px]`}>
                    {component[selected]}
                </div>
            </div>
        </ReactModal >
    )
}

function FirstView({ setSelected=()=>{}, setLoading=()=>{}, accountInfo }){

    function firstClick(){
        setLoading(true)
        GetRequest(`${process.env.REACT_APP_URL}/account/email/send/${accountInfo?._id}`).then((response) => {
            console.log("sent");
            setLoading(false)
            setSelected(1)
        }).catch((error) => {
            console.error("Errors sending otp to email", error);
            setLoading(false)
        });
    }
    return(
        <div>
            <Label title='Confirm Email' />
            <TextInput 
                value={accountInfo?.email}
                disabled
            />
            <Button 
                label='Send OTP'
                onClick={firstClick}
                type='secondary'
                className='mt-[30px]'
            />
        </div>
    )
}

function SecondView({ setSelected=()=>{}, setLoading=()=>{}, accountInfo }){
    const [otp, setOtp] = useState("");
    const [error, setError] = useState("");
    const dispatch = useDispatch();

    function secondClick(){
        setLoading(true)
        if (!otp?.trim()){
            setError("Enter OTP first!")
            setLoading(false)
        }else{
            GetRequest(`${process.env.REACT_APP_URL}/account/email/verify?id=${accountInfo?.data?._id}&otp=${otp}`).then(response => {
                dispatch(accountActions.setAccountInfo({
                    data: response.data,
                    convoId: accountInfo.convoId,
                    permissions: accountInfo.permissions,
                    projectPermissions: accountInfo.projectPermissions
                }));
                setSelected(2)
                setLoading(false)
            }).catch(err => {
                console.log("Errors verifying otp", err);
                setLoading(false)
                setError(err.data)
            });
        }
    }
    return(
        <div>
            {error ? (
                <Error message={error} className='text-[12px]' />
            ):(
                <p className='text-success text-[12px]'>One-Time Password has been sent to your email.</p>
            )}
            <Label title='Enter OTP' />
            <TextInput 
                value={otp}
                onChange={(e) => {
                    setOtp(e.target.value)
                    setError("")
                }}
            />
            <Button 
                label='Confirm'
                onClick={secondClick}
                type='secondary'
                className='mt-[30px]'
            />
        </div>
    )
}

function ThirdView({ closeHandler=()=>{} }){
    useEffect(() => {
        const timer = setTimeout(() => {
            closeHandler()
        }, 1500);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return(
        <div className='py-[30px]'>
            <FaCheckCircle className='text-success mx-auto' fontSize={30} />
            <p className='text-[30px] text-success font-bold text-center'>Email Verified Successfully!</p>
        </div>
    )
}

export default EmailVerification;