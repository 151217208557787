import React, { useEffect, useState } from 'react';
import SuperAdminLayout from '../../layout/superAdmin';
import { GetRequest } from '../../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import Search from '../../components/custom/search';
import { FiArrowUp, FiArrowDown } from "react-icons/fi";
import { employeeActions } from '../../store/employee-slice';
import ContactData from '../../components/contact/contactData';
import Pagination from '../../components/custom/pagination';
import { uiActions } from '../../store/ui-slice';

const Enquiries = () => {
    const dispatch = useDispatch();
    const { accountInfo } = useSelector((state) => state.account);
    const { enquiryList } = useSelector((state) => state.employee);

    const [searchKey, setSearchKey] = useState("");
    const [filteredList, setFilteredList] = useState("");
    const [open, setOpen] = useState(false);
    const [sortBy, setSortBy] = useState("latest");

    useEffect(() => {
        if (accountInfo){
            const config = {
                headers: {
                    Authorization: `Bearer ${accountInfo?.token}`,
                },
            }
            GetRequest(`${process.env.REACT_APP_URL}/contact`, config).then(response => {
                dispatch(employeeActions.setEnquiryList(response.data))
                setFilteredList(enquiryList)
            }).catch(error => {
                console.log("fetch enquiries error >>>", error.data);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[accountInfo])

    useEffect(() => {
        if (searchKey.length > 0){
            const filtered = enquiryList.length > 0 && enquiryList.filter(index => {
                return index.fullName?.includes(searchKey) || index.email?.includes(searchKey) || index.phone?.toString().includes(searchKey)
            })
            setFilteredList(filtered);
        }else{
            setFilteredList(enquiryList);
        }
    },[searchKey, enquiryList])

    useEffect(() => {
        if (enquiryList.length > 0){
            if (sortBy === "latest"){
                let sortedLatest = enquiryList.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setFilteredList(sortedLatest);
            }else{
                let sortedOldest = enquiryList.slice().sort((a, b) =>  new Date(a.createdAt) - new Date(b.createdAt));
                setFilteredList(sortedOldest);
            }
        }
    },[sortBy, enquiryList])

    return (
        <SuperAdminLayout>
            <div className="max-md:px-[15px] md:px-[30px] pt-[40px] pb-[30px]">
                <div className="flex justify-between items-center mb-[30px] border-b px-[10px] lg:px-[30px]">
                    <h3>Enquiries</h3>
                </div>
                <div className='mt-[20px] pb-[50px] w-full'>
                    <div className='flex justify-between items-center'>
                        <Search
                            value={searchKey}
                            onChange={(e) => {
                                setSearchKey(e.target.value);
                                dispatch(uiActions.setPaginationOffset(0))
                            }}
                            className='max-w-[250px]'
                            placeholder="Can't find? Search here..."
                        />
                        <div className='relative border rounded-full bg-white max-w-[120px] px-[10px] h-[40px] w-full text-center flex justify-center items-center'>
                            <button onClick={() => setOpen(prev => !prev)} className='w-full capitalize'>Sort By: {sortBy}</button>
                            <div className={`absolute top-[40px] left-[10px] right-[10px] z-[99] bg-white shadow-md ${open ? "grid" : "hidden"}`} onMouseLeave={() => setOpen(false)}>
                                {sortList.map((index) => (
                                    <button 
                                        onClick={() => {
                                            setSortBy(index.label)
                                            setOpen(false)
                                        }} 
                                        className='py-[5px] hover:bg-[#f8f8ff] capitalize flex items-center gap-[10px] justify-between px-[15px] border-b last-child:border-none'
                                    >{index.label} {index.icon}</button>
                                ))}
                            </div>
                        </div>
                    </div>
                    
                    <Pagination
                        itemsPerPage={20}
                        data={filteredList}
                        renderComponent={(currentItems) => (
                            <ContactData data={currentItems} />
                        )}
                    />
                </div>
            </div>
        </SuperAdminLayout>
    )
}

const sortList = [
    {
        label: "latest",
        icon: <FiArrowUp />
    },
    {
        label: "oldest",
        icon: <FiArrowDown />
    }
];

export default Enquiries;