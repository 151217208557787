import React, { useState, useEffect } from "react";
import { IoAdd, IoClose } from "react-icons/io5";
import { PostRequest, GetRequest } from "../utils/request";
import Loader from "../components/custom/loader";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "../components/custom/avatar";
import ReactModal from ".";
import Button from "../components/custom/button";
import TextInput from "../components/custom/textInput";
import Label from "../components/custom/label";
import RichText from "../components/custom/richText";
import { chatActions } from '../store/chat-slice';
import { employeeActions } from '../store/employee-slice';
import socket from "../socket";
import TaskSocket from '../socket/task-socket';
import { IoMdClose } from "react-icons/io";
import AddAssignees from "./addAssignees";
import { taskActions } from "../store/task-slice";
import getInitialLetter from "../utils/getInitialLetter";
import { projectActions } from "../store/project-slice";

const AddTask = ({ icon, item, projectId, milestones, setTrigger = () => { }, className = "" }) => {
    const dispatch = useDispatch()
    const { accountInfo } = useSelector((state) => state.account);
    const { assigneesModalOpen, assignees } = useSelector((state) => state.task);
    const { projectTrigger } = useSelector((state) => state.project);

    TaskSocket.useTaskRoomSetup(projectId, accountInfo?.data?._id)

    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState("");
    const [formInput, setFormInput] = useState({
        title: "",
        description: "",
        dueDate: "",
        assignees: [],
        departments: []
    })
    const [milestone, setMilestone] = useState(null);

    const [show, setShow] = useState({
        milestone: false,
        assignees: false
    });

    const nameHandler = (e) => {
        const { value } = e.target;
        const cleanedValue = value.replace(/[^A-Za-z\s]/g, '');
        setFormInput(prevState => ({ ...prevState, title: cleanedValue }));
        setError("");
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "dueDate") {
            const [year, month, day] = value.split(/[-T]/);
            if (year.length > 4) {
                const validYear = year.slice(0, 4);
                const newValue = `${validYear}-${month}-${day}${value.includes('T') ? 'T' + value.split('T')[1] : ''}`;
                setFormInput((prevInput) => ({
                    ...prevInput,
                    [name]: newValue,
                }));
                return;
            }
        }
        setError("")
        setFormInput((prevInput) => ({
            ...prevInput,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (accountInfo) {
            GetRequest(`${process.env.REACT_APP_URL}/task/getAssignee/${accountInfo.data._id}`).then(response => {
                dispatch(chatActions.setAssigneeList(response?.data));
            }).catch(error => {
                console.log("getAssignee error >>>", error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo])

    useEffect(() => {
        if (accountInfo) {
            GetRequest(`${process.env.REACT_APP_URL}/user/employees/${accountInfo.data.adminId || accountInfo.data._id}`).then(response => {
                dispatch(employeeActions.setEmployees(response?.data))
            }).catch((error) => {
                console.log("admin error >", error);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo]);

    const taskSubmitHandler = (e) => {
        setDisabled(true)
        setError("")
        if (!formInput.title) {
            setError("Title must not be empty!")
            setDisabled(false)
        } else {
            const body = {
                ...formInput,
                projectId,
                milestoneId: milestone._id,
                assignees: formInput.assignees.map((assignee) => assignee._id),
                departments: formInput.departments.map((department) => department._id),
                assignedAt: new Date().toISOString(),
                createdBy: accountInfo?.data._id,
                admin: accountInfo?.data.adminId || accountInfo?.data._id,
            }

            PostRequest(`${process.env.REACT_APP_URL}/task`, body).then((response) => {
                if (response?.data?.assignees?.length > 0 && Array.isArray(response?.data.assignees)) {
                    response?.data.assignees.forEach((id) => {
                        socket.emit('taskadded', id._id, projectId, accountInfo?.data.fullName, response?.data.projectTitle, "assignee added")
                    });
                }
                if (assignees.length > 0 && Array.isArray(assignees)) {
                    assignees.forEach((item) => {
                        socket.emit('taskadded', item._id, projectId, accountInfo?.data.fullName, response?.data.projectTitle)
                    })
                }
                setDisabled(false);
                setTrigger(prev => prev + 1);
                closeHandler();
                dispatch(projectActions.setProjectTrigger(projectTrigger + 1))
            }).catch((error) => {
                setDisabled(false);
                console.log("error creating task", error);
                setError(error.data || "Failed during task creation!");
            })
        }
    }

    const handleKeyDown = (e) => {
        const { value } = e.target;
        if (e.target.name === "dueDate") {
            const year = value.slice(0, 4);
            if (year.length >= 4 && e.key >= "0" && e.key <= "9") {
                e.preventDefault();
            }
        }
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            taskSubmitHandler();
        }
    };

    function clickDepartmentHandler(data) {
        if (!formInput.departments.map(department => department?._id).includes(data?._id)) {
            setFormInput(prevState => ({ ...prevState, departments: [...formInput.departments, data] }));
        } else {
            const filtered = formInput.departments.filter(department => department?._id !== data?._id);
            setFormInput(prevState => ({ ...prevState, departments: filtered }));
        }
    };

    function clickUserHandler(data) {
        if (!formInput.assignees.map(assignee => assignee?._id).includes(data?._id)) {
            setFormInput(prevState => ({ ...prevState, assignees: [...formInput.assignees, data] }));
        } else {
            const filtered = formInput.assignees.filter(assignee => assignee?._id !== data?._id);
            setFormInput(prevState => ({ ...prevState, assignees: filtered }));
        }
    };
    function closeHandler() {
        setOpen(false);
        setFormInput({
            title: "",
            description: "",
            dueDate: "",
            departments: [],
            assignees: []
        });
        setError("");
        setDisabled(false);
    }

    let allAssignees = [...formInput.assignees, ...formInput.departments]

    return (
        <>
            <button
                onClick={() => { setOpen(true); setMilestone(item) }}
                className={`bg-white rounded-full ${className}`}
            >
                {icon}
            </button>
            <ReactModal open={open} noClose maxWidth="700px" padding="20px" heading="Add Task" >
                <button onClick={() => setOpen(false)} className='absolute top-[20px] right-[15px]'>
                    <IoMdClose size={20} />
                </button>
                {disabled && <Loader />}
                {assigneesModalOpen &&
                    <AddAssignees
                        clickDepartmentHandler={clickDepartmentHandler}
                        clickUserHandler={clickUserHandler}
                        assignees={formInput.assignees}
                        departments={formInput.departments}
                    />
                }
                <span className='text-[14px] text-error'>{error}</span>

                <div className="w-full">
                    <Label title="Title" className="mb-[5px]" />
                    <TextInput
                        name="title"
                        value={formInput.title}
                        onChange={nameHandler}
                        maxLength={40}
                        onKeyDown={handleKeyDown}
                    />
                    <div className="grid md:grid-cols-3 md:gap-[20px] max-md:gap-[15px] mt-[15px]">
                        <div className="relative">
                            <Label title="Status" className="mb-[10px]" />
                            <div className="text-white px-[10px] text-[14px] py-[10px] cursor-pointer capitalize select-none"
                                style={{ backgroundColor: milestone?.color }}
                                onClick={() => setShow(prevState => ({ ...prevState, milestone: !show.milestone }))}
                            >{milestone?.title}</div>
                            <div onMouseLeave={() => setShow(prevState => ({ ...prevState, milestone: false }))} className={`mt-[5px] absolute top-[55px] w-full bg-white p-[10px] shadow-md z-10 ${show.milestone ? "grid" : "hidden"}`}>
                                {milestones?.length > 0 && milestones.map((item, i) => (
                                    <div
                                        key={i}
                                        className="flex gap-[10px] cursor-pointer py-[3px]"
                                        onClick={() => {
                                            setMilestone(item);
                                            setShow(prevState => ({ ...prevState, milestone: false }));
                                        }}
                                        onKeyDown={handleKeyDown}
                                    >
                                        <div className='h-[13px] min-w-[13px] rounded-full border flex justify-center items-center mt-[5px]' style={{ borderColor: item.color }}>
                                            <div className='h-[9px] min-w-[9px] rounded-full' style={{ backgroundColor: item.color }} />
                                        </div>
                                        <span className="text-[14px] capitalize select-none">{item.title}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="relative">
                            <Label title="Assignees" className="mb-[10px]" />
                            <div className="flex flex-wrap items-center" >
                                {allAssignees?.length > 0 && (
                                    <div className="relative flex items-center" >
                                        {allAssignees.slice(0, 3).map((item, i) => (
                                            <>
                                                {item.title ? (
                                                    <>
                                                        <div title={item.title} className='h-[40px] w-[40px] rounded-full border border-[#aaa] flex justify-center items-center ml-[-5px] first:ml-0 bg-secondary'>
                                                            {getInitialLetter(item.title)}
                                                        </div>
                                                        {/* {isAdmin &&
                                                    <IoIosClose
                                                        size={20}
                                                        className="absolute top-0 right-0 z-[999] bg-white rounded-full cursor-pointer group-hover:block hidden"
                                                        onClick={() => {
                                                            updateAssigneeHandler({department: item?._id})
                                                            const filtered = assignees.filter(assignee => assignee._id !== item._id)
                                                            setAssignees(filtered)
                                                        }}
                                                    />
                                                } */}
                                                    </>
                                                ) : (
                                                    <>
                                                        <Avatar
                                                            src={item.avatar}
                                                            alt={item.fullName}
                                                            title={item.fullName}
                                                            key={i}
                                                            noOnline
                                                            className={`ml-[-5px] first:ml-0`}
                                                        />
                                                        {/* {isAdmin &&
                                                    <IoIosClose
                                                        size={20}
                                                        className="absolute top-0 right-0 z-[999] bg-white rounded-full cursor-pointer group-hover:block hidden"
                                                        onClick={() => {
                                                            updateAssigneeHandler({assignee: item?._id})
                                                            const filtered = assignees.filter(assignee => assignee._id !== item._id)
                                                            setAssignees(filtered)
                                                        }}
                                                    />
                                                } */}
                                                    </>
                                                )}
                                            </>
                                        ))}
                                        {allAssignees.length > 3 && (
                                            <div className='h-[40px] w-[40px] flex justify-center items-center border border-[#aaa] rounded-full bg-white ml-[-10px] relative'>+{allAssignees.length - 3}</div>
                                        )}
                                    </div>
                                )}
                                <button onClick={() => dispatch(taskActions.setAssigneesModalOpen(true))} className={allAssignees.length === 0 && "mt-[8px]"}>
                                    <IoAdd size={20} />
                                </button>
                            </div>
                            <div className={`absolute top-[60px] bg-white p-[10px] shadow-[0px_0px_15px_0px_rgba(0,0,0,0.15)] z-[1] overflow-hidden overflow-y-auto max-h-[180px] min-w-[200px] ${show.assignees ? "grid" : "hidden"}`}>
                                <button className="absolute top-[5px] right-[5px]" onClick={() => setShow(prevState => ({ ...prevState, assignees: false }))}>
                                    <IoClose size={20} />
                                </button>
                            </div>
                        </div>
                        {/* <div>

                        <div>
                            <Label title="Due Date" className="mb-[10px]" />
                            <TextInput
                                name="dueDate"
                                type="datetime-local"
                                value={formInput.dueDate}
                                className="w-full border select-none px-[10px] py-[10px] text-[12px]"
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                max="9999-12-31T23:59"
                                step="1"
                            />
                        </div> */}

                        {/* <div>
                            <Label title="Due Date" className="mb-[10px]" />
                            <TextInput
                                name="dueDate"
                                type="datetime-local"
                                value={formInput.dueDate}
                                className="w-full border select-none px-[10px] py-[10px] text-[12px]"
                                onChange={(e) => {
                                    const inputDate = e.target.value;
                                    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;

                                    if (iso8601Regex.test(inputDate)) {
                                        const [datePart, timePart] = inputDate.split('T');
                                        const [year, month, day] = datePart.split('-');
                                        const [hours, minutes] = timePart.split(':');

                                        if (
                                            parseInt(year) <= 9999 &&
                                            parseInt(month) >= 1 && parseInt(month) <= 12 &&
                                            parseInt(day) >= 1 && parseInt(day) <= 31 &&
                                            parseInt(hours) >= 0 && parseInt(hours) <= 23 &&
                                            parseInt(minutes) >= 0 && parseInt(minutes) <= 59
                                        ) {
                                            setFormInput((prevState) => ({
                                                ...prevState,
                                                dueDate: inputDate,
                                            }));
                                        } else {
                                            console.error('Invalid date or time values.');
                                        }
                                    } else {
                                        console.error('Invalid ISO 8601 format.');
                                    }
                                }}
                                onKeyDown={(e) => {
                                    const inputDate = e.target.value;
                                    const year = inputDate.split('-')[0];

                                    if (year.length >= 4 && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                                        e.preventDefault();
                                    }
                                }}
                                onBlur={(e) => {
                                    const inputDate = e.target.value;
                                    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;

                                    if (!iso8601Regex.test(inputDate)) {
                                        alert('Invalid ISO 8601 format. Please ensure the input is YYYY-MM-DDTHH:mm');
                                        setFormInput((prevState) => ({
                                            ...prevState,
                                            dueDate: '',
                                        }));
                                    }
                                }}
                                max="9999-12-31T23:59"
                                step="1"
                            />
                        </div> */}

                        <div>
                            <Label title="Due Date" className="mb-[10px]" />
                            <TextInput
                                name="dueDate"
                                type="datetime-local"
                                value={formInput.dueDate}
                                className="w-full border select-none px-[10px] py-[10px] text-[12px]"
                                onChange={(e) => {
                                    const inputDate = e.target.value;
                                    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2})?$/; // Allows optional seconds.

                                    if (iso8601Regex.test(inputDate)) {
                                        const [datePart, timePart] = inputDate.split('T');
                                        const [year, month, day] = datePart.split('-');
                                        const [hours, minutes, seconds] = timePart.split(':');

                                        if (
                                            parseInt(year) <= 9999 &&
                                            parseInt(month) >= 1 && parseInt(month) <= 12 &&
                                            parseInt(day) >= 1 && parseInt(day) <= 31 &&
                                            parseInt(hours) >= 0 && parseInt(hours) <= 23 &&
                                            parseInt(minutes) >= 0 && parseInt(minutes) <= 59 &&
                                            (!seconds || (parseInt(seconds) >= 0 && parseInt(seconds) <= 59))
                                        ) {
                                            setFormInput((prevState) => ({
                                                ...prevState,
                                                dueDate: inputDate,
                                            }));
                                        } else {
                                            console.error('Invalid date or time values.');
                                        }
                                    } else {
                                        console.error('Invalid ISO 8601 format.');
                                    }
                                }}
                                onKeyDown={(e) => {
                                    const inputDate = e.target.value;
                                    const year = inputDate.split('-')[0];

                                    if (year.length >= 4 && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                                        e.preventDefault();
                                    }
                                }}

                                onBlur={(e) => {
                                    const inputDate = e.target.value;
                                    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2})?$/;
                                    if (!iso8601Regex.test(inputDate)) {
                                        alert('Invalid ISO 8601 format. Please ensure the input is YYYY-MM-DDTHH:mm');
                                        setFormInput((prevState) => ({
                                            ...prevState,
                                            dueDate: '',
                                        }));
                                    }
                                }}
                            // onKeyDown={(e) => {
                            //     const inputDate = e.target.value;
                            //     const year = inputDate.split('-')[0];
                            //     // add task

                            //     if (year.length >= 4 && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                            //         e.preventDefault();
                            //     }
                            // }}
                            // max="9999-12-31T23:59"
                            // step="1"
                            />
                        </div>




                    </div>
                    <div className="w-full mt-[15px]">
                        <Label title="Description" className="mb-[5px]" htmlFor="description" />
                        <RichText
                            value={formInput.description}
                            onChange={(input) => setFormInput(prevState => ({ ...prevState, description: input }))}
                            placeholder="Write description here..."
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    <div className="flex gap-[10px] justify-center text-center mt-[30px]">
                        <Button
                            label="Cancel"
                            type='secondary'
                            onClick={closeHandler}
                        />
                        <Button
                            label="Submit"
                            onClick={taskSubmitHandler}
                        />
                    </div>
                </div>
            </ReactModal>
        </>
    );
};

export default AddTask;
