import React from 'react'
import TextInput from '../custom/textInput';
import Label from '../custom/label';

const PersonalInfo = ({
    formInput, 
    setFormInput=()=>{},
    setError=()=>{},
    onNextClick=()=>{}
}) => {

    //FULL NAME INPUT HANDLER
    const nameHandler = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(/[^A-Za-z\s]/g, '');
        const trimmedValue = cleanedValue.trim();

        if (trimmedValue === "") {
            setFormInput((prevState) => ({
                ...prevState,
                [name]: "" 
            }));
        } else {
            setFormInput((prevState) => ({
                ...prevState,
                [name]: cleanedValue
            }));
        }
        setError("");
    };

    //PHONE NUMBER INPUT HANDLER
    const phoneHandler = (e) => {
        const { name, value } = e.target
        const numericValue = value.replace(/\D/g, "");
        if (numericValue.length <= 10) {
            setFormInput((prevState) => ({ ...prevState, [name]: numericValue }));
            setError("");
        }
    };

    //EMAIL INPUT HANDLER
    const emailHandler = (e) => {
        const inputValue = e.target.value;
        const val = inputValue.replace(" ", '')?.toLowerCase();
        setFormInput((prevState) => ({ ...prevState, email: val }));
        setError("");
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            onNextClick()
        }
    };
    
    return (
        <div>
            <div className="mb-[10px]">
                <Label
                    title='Full Name'
                    htmlFor="fullName"
                    isImportant
                />
                <TextInput
                    id='fullName'
                    name="fullName"
                    value={formInput.fullName}
                    onChange={nameHandler}
                    onKeyDown={handleKeyDown}
                    maxLength={30}
                />
            </div>
            <div className="mb-[10px]">
                <Label
                    title='Email'
                    htmlFor="Email"
                    isImportant
                />
                <TextInput
                    id='Email'
                    value={formInput.email}
                    type='email'
                    onChange={emailHandler}
                    onKeyDown={handleKeyDown}
                    maxLength={50}
                />
            </div>
            <div className="mb-[10px]">
                <Label
                    title='Phone Number'
                    htmlFor="mobile"
                    isImportant
                />
                <div className='relative'>
                    <span className='absolute top-[15px] left-[5px]'>+91</span>
                    <TextInput
                        name="mobile"
                        value={formInput.mobile}
                        onChange={phoneHandler}
                        className='mt-[5px] pl-[33px]'
                        onKeyDown={handleKeyDown}
                    />
                </div>
            </div>
            <div className='flex justify-end items-center mt-[30px]'>
                <button
                    className='w-full max-w-[100px] bg-primary text-default py-[5px]'
                    onClick={onNextClick}
                >Next</button>
            </div>
        </div>
    )
}

export default PersonalInfo;