import React, { useEffect, useRef, useState } from 'react'
import Layout from '../layout';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteRequest, GetRequest, PutRequest } from '../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import AddAssignees from '../popups/addAssignees';
import { taskActions } from '../store/task-slice';
import Activities from '../components/task/activities';
import { IoAdd, IoRadioButtonOnSharp } from 'react-icons/io5';
import { LuTimer, LuUsers2 } from 'react-icons/lu';
import { IoIosClose } from 'react-icons/io';
import getInitialLetter from '../utils/getInitialLetter';
import Avatar from '../components/custom/avatar';
import { SlCalender } from 'react-icons/sl';
import Timer from '../components/task/timer';
import { formatDateForInput } from '../utils/formatTimestamp';
import TaskSocket from "../socket/task-socket";
import { AiOutlineClose, AiTwotoneDelete } from 'react-icons/ai';
import { uiActions } from '../store/ui-slice';
import RichText from '../components/custom/richText';
import { FiEdit2 } from 'react-icons/fi';
import IconButton from '../components/custom/iconButton';
import { FaRegCircle } from 'react-icons/fa6';
import { BsFlagFill } from 'react-icons/bs';
import { employeeActions } from '../store/employee-slice';
import { projectActions } from '../store/project-slice';
import DeleteConfirmation from '../popups/deleteConfirmation';

const Task = () => {
    const titleInputRef = useRef(null);
    const { taskId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { accountInfo } = useSelector((state) => state.account);
    const isAdmin = accountInfo?.data.role?.title?.toLowerCase() === "admin";
    const { selectedTaskData, assigneesModalOpen, activityTrigger, assignees } = useSelector((state) => state.task);
    const { projectPermissions, activeProject, activeProjectMilestones, projectTrigger } = useSelector((state) => state.project);

    const [formInput, setFormInput] = useState({
        title: selectedTaskData?.title || "",
        description: selectedTaskData?.description || "",
        dueDate: selectedTaskData?.dueDate || null,
        priority: selectedTaskData?.priority || "low"
    })
    const [show, setShow] = useState({
        milestone: false,
        assignees: false,
        priority: false
    });
    const [isEdit, setIsEdit] = useState(false);
    const [selectedMilestone, setSelectedMilestone] = useState(selectedTaskData?.milestoneId);
    const [users, setUsers] = useState(selectedTaskData?.assignees);
    const [allDepartments, setAllDepartments] = useState(selectedTaskData?.departments);
    const [projectData, setProjectData] = useState(null);

    const formattedDate = formatDateForInput(formInput?.dueDate);

    useEffect(() => {
        if (isEdit) {
            titleInputRef.current.focus();
        }
    }, [isEdit]);

    // FETCH ALL ASSIGNESS OF A PROJECT
    useEffect(() => {
        if (projectData) {
            GetRequest(`${process.env.REACT_APP_URL}/project/assignee/${projectData._id}`).then(response => {
                dispatch(taskActions.setAssignees([...response.data, projectData.admin, projectData.createdBy]))
            }).catch(error => {
                console.log("err getting assignees list >", error);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectData, projectTrigger])

    // FETCHES CURRENT TASK DATA
    useEffect(() => {
        if (taskId) {
            GetRequest(`${process.env.REACT_APP_URL}/task/${taskId}`).then(response => {
                dispatch(taskActions.setSelectedTaskData(response.data))
            }).catch(error => {
                console.log("task fetch error >>>", error.data);
                dispatch(uiActions.setToastify({
                    isSuccess: false,
                    message: "Unable to fetch Task data!"
                }))
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskId, projectTrigger])

    // SET INITIAL VALUES TO FORMINPUT STATE
    useEffect(() => {
        if (selectedTaskData) {
            setFormInput({
                title: selectedTaskData?.title || "",
                description: selectedTaskData?.description || "",
                dueDate: selectedTaskData?.dueDate || null,
                priority: selectedTaskData?.priority || "low"
            })
            setSelectedMilestone(selectedTaskData?.milestoneId)
            setAllDepartments(selectedTaskData?.departments)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTaskData]);

    // JOINS USER AND DEPARTMENT ARRAY INTO ASSIGNEE ARRAY
    useEffect(() => {
        if (selectedTaskData) {
            const allAssignees = [...selectedTaskData?.assignees, ...selectedTaskData?.departments];
            setUsers(allAssignees)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTaskData]);

    //FETCHES MILESTONES OF GIVEN TASK FROM ITS PROJECT
    useEffect(() => {
        if (taskId && accountInfo) {
            GetRequest(`${process.env.REACT_APP_URL}/task/milestones/${taskId}?id=${accountInfo?.data._id}`).then((response) => {
                dispatch(projectActions.setProjectPermissions(response.data.permissions))
                dispatch(projectActions.setActiveProjectMilestones(response.data.milestones));
                setProjectData(response.data.project);
            }).catch((error) => {
                console.error("Error fetching user details:", error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [taskId, accountInfo, projectTrigger]);

    //FETCHES EMPLOYEES LIST
    useEffect(() => {
        if (accountInfo) {
            GetRequest(`${process.env.REACT_APP_URL}/user/employees/${accountInfo.data.adminId || accountInfo.data._id}`).then(response => {
                dispatch(employeeActions.setEmployees(response.data))
            }).catch((error) => {
                console.log("admin error >", error);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo]);

    // UPDATES MILESTONE/STATUS OF THE TASK
    function updateMilestoneHandler(milestoneId) {
        PutRequest(`${process.env.REACT_APP_URL}/task/${selectedTaskData?._id}?userId=${accountInfo?.data._id}`, {
            milestoneId: milestoneId,
            updatedBy: accountInfo?.data.fullName
        }).then(response => {
            TaskSocket.emitTaskUpdate(activeProject?._id, response.data, assignees, "milestone updated")
            dispatch(taskActions.setActivityTrigger(activityTrigger + 1))
        }).catch(error => {
            console.log("error updating milestone", error.data)
        })
    }

    // UPDATES TITLE, DESCRIPTION & DUEDATE OF THE TASK
    function updateHandler(body) {
        const formData = new FormData();
        if (formInput?.title?.toLowerCase() !== selectedTaskData?.title?.toLowerCase()) {
            formData.append('title', formInput?.title);
        }
        if (formInput?.description !== selectedTaskData?.description) {
            formData.append('description', formInput?.description);
        }
        if (formInput?.dueDate !== selectedTaskData?.dueDate) {
            formData.append('dueDate', new Date(formInput.dueDate));
        }
        if (formInput?.priority !== selectedTaskData?.priority) {
            formData.append('priority', formInput?.priority);
        }
        formData.append('updatedBy', accountInfo?.data.fullName);
        let data = Object.keys(body).length === 0 ? formData : body;
        PutRequest(`${process.env.REACT_APP_URL}/task/${selectedTaskData?._id}?userId=${accountInfo?.data._id}`, data).then(response => {
            setIsEdit(false)
            dispatch(uiActions.setToastify({
                isSuccess: true,
                message: "Updated Successfully!"
            }))
            TaskSocket.emitTaskUpdate(activeProject?._id, response.data, assignees, "task updated")
        }).catch(error => {
            console.log("error updating due date", error)
            dispatch(uiActions.setToastify({
                isSuccess: false,
                message: "Updation failed!"
            }))
        })
    }

    //DELETES TASK
    function deleteTaskHandler() {
        DeleteRequest(`${process.env.REACT_APP_URL}/task/${selectedTaskData?._id}?userId=${accountInfo?.data._id}`).then(response => {
            // setTrigger(prev => prev + 1);
            TaskSocket.emitTaskUpdate(activeProject?._id, response.data, assignees, "task deleted")
            navigate(`/project/${activeProject?._id}`)
        }).catch(error => {
            console.log("delete task error >>>", error.data);
        });
    }

    //UPDATES ASSIGNEES OF A TASK
    function updateAssigneeHandler(body) {
        body.updatedBy = accountInfo?.data.fullName;
        PutRequest(`${process.env.REACT_APP_URL}/task/assignee/${selectedTaskData?._id}?userId=${accountInfo?.data._id}`, body).then(response => {
            TaskSocket.emitTaskUpdate(activeProject?._id, response.data, assignees, "task updated")
            // setTrigger(prev => prev + 1);
        }).catch(error => {
            console.log("error updating milestone", error)
        })
    };

    //UPDATES DEPARTMENT ASSIGNEES OF A TASK
    function clickDepartmentHandler(data) {
        updateAssigneeHandler({ department: data?._id })
        if (!allDepartments.map(department => department?._id).includes(data?._id)) {
            setAllDepartments([...allDepartments, data]);
        } else {
            setAllDepartments(allDepartments.filter(department => department?._id !== data?._id));
        }
    };

    //UPDATES USER ASSIGNEES OF A TASK
    function clickUserHandler(data) {
        updateAssigneeHandler({ assignee: data?._id })
        if (!users.map(assignee => assignee?._id).includes(data?._id)) {
            setUsers([...users, data]);
        } else {
            setUsers(users.filter(assignee => assignee?._id !== data?._id));
        }
    };

    const isDateExpired = (dateTimeString) => {
        const inputDate = new Date(dateTimeString);
        const currentDate = new Date();
    
        // Compare only the date part, ignoring time
        const isExpired = inputDate < currentDate;
    
        return isExpired;
    };

    return (
        <Layout>
            {assigneesModalOpen &&
                <AddAssignees
                    clickDepartmentHandler={clickDepartmentHandler}
                    clickUserHandler={clickUserHandler}
                    assignees={users}
                    departments={allDepartments}
                />
            }
            <div className='p-[30px] my-[30px] shadow-md bg-white'>
                {selectedTaskData?.dueDate && isDateExpired(selectedTaskData?.dueDate) && (
                    <div title='Contact your Manager!' className='text-error font-semibold pb-[10px]'>This task has been expired!</div>
                )}
                <div className='w-full'>

                    {/* TITLE OF THE TASK */}
                    <div className='border-b pb-[10px] flex items-center gap-[30px]'>
                        <input
                            ref={titleInputRef}
                            maxLength={40}
                            minLength={5}
                            disabled={!isEdit}
                            value={formInput.title}
                            onChange={(e) => {
                                setFormInput(prevState => ({ ...prevState, title: e.target.value }))
                            }}
                            className='capitalize border-none select-none focus:outline-none font-semibold h-[35px] px-[10px] w-full text-[25px]'
                        />
                        {isEdit ? (
                            <div className="flex gap-[5px]">
                                <IconButton
                                    title="Update"
                                    icon={<FaRegCircle size={16} color="#008000" />}
                                    className="bg-white h-[30px] w-[30px]"
                                    onClick={() => updateHandler({})}
                                />
                                <IconButton
                                    title="Cancel"
                                    icon={<AiOutlineClose size={18} color="#FF0000" />}
                                    className="bg-white h-[30px] w-[30px]"
                                    onClick={() => {
                                        setIsEdit(false)
                                        setFormInput(prevState => ({ ...prevState, title: selectedTaskData?.title }))
                                    }}
                                />
                            </div>
                        ) : (
                            (isAdmin || projectPermissions?.update) && (
                                <FiEdit2
                                    title="Edit Task"
                                    onClick={() => setIsEdit(true)}
                                    size={15}
                                    color="#aaa"
                                    className='cursor-pointer'
                                />
                            )
                        )}
                    </div>

                    <div className='grid md:grid-cols-2 md:mt-[30px] max-md:mt-[20px] gap-x-[70px] gap-y-[20px]'>

                        {/* STATUS OF THE TASK */}
                        <div className='relative flex w-full md:max-w-[300px] justify-between relative items-center'>
                            <span className='text-[14px] flex items-center w-full'><IoRadioButtonOnSharp size={15} className='mr-[5px]' /> Status</span>
                            <div className='relative w-full'>
                                <div
                                    className="px-[10px] text-[12px] py-[5px] cursor-pointer select-none w-full text-white uppercase rounded-[5px] flex w-full"
                                    style={{ backgroundColor: selectedMilestone?.color }}
                                    onClick={() => setShow(prevState => ({ ...prevState, milestone: !show.milestone }))}
                                >{selectedMilestone?.title}
                                </div>
                                {activeProjectMilestones?.length > 0 && (
                                    <div className={`absolute top-[31px] left-0 min-w-[190px] w-full p-[10px] bg-white rounded-[10px] shadow-md z-[10] ${show.milestone ? "grid" : "hidden"}`} onMouseLeave={() => setShow(prevState => ({ ...prevState, milestone: false }))}>
                                        {activeProjectMilestones.map((item, i) => (
                                            <button
                                                key={i}
                                                className="flex gap-[10px] cursor-pointer py-[3px] items-center border-b last:border-b-0 bg-hover px-[5px]"
                                                onClick={() => {
                                                    setSelectedMilestone(item);
                                                    updateMilestoneHandler(item?._id)
                                                    setShow(prevState => ({ ...prevState, milestone: false }));
                                                }}
                                            >
                                                <div className='h-[13px] w-[13px] rounded-full border flex justify-center items-center' style={{ borderColor: item.color }}>
                                                    <div className='h-[9px] w-[9px] rounded-full' style={{ backgroundColor: item.color }} />
                                                </div>
                                                <span className="text-[12px] uppercase select-none text-left">{item?.title}</span>
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* ASSIGNEES OF THE TASK */}
                        <div className='flex w-full md:max-w-[300px] justify-between gap-[15px]'>
                            <span className='text-[14px] flex items-center w-full'><LuUsers2 size={15} className='mr-[5px]' /> Assignees</span>
                            <div className="relative flex items-center w-full" >
                                {users?.length > 0 && (
                                    <div className="relative flex items-center w-full" >
                                        {users.slice(0, 3).map((item, i) => (
                                            <>
                                                {item.title ? (
                                                    <>
                                                        <div title={item.title} className='h-[40px] w-[40px] rounded-full border border-[#aaa] flex justify-center items-center ml-[-5px] first:ml-0 bg-secondary'>
                                                            {getInitialLetter(item.title)}
                                                        </div>
                                                        {isAdmin &&
                                                            <IoIosClose
                                                                size={20}
                                                                className="absolute top-0 right-0 z-[999] bg-white rounded-full cursor-pointer group-hover:block hidden"
                                                                onClick={() => {
                                                                    updateAssigneeHandler({ department: item?._id })
                                                                    const filtered = users.filter(assignee => assignee._id !== item._id)
                                                                    setUsers(filtered)
                                                                }}
                                                            />
                                                        }
                                                    </>
                                                ) : (
                                                    <>
                                                        <Avatar
                                                            src={item.avatar}
                                                            alt={item.fullName}
                                                            title={item.fullName}
                                                            key={i}
                                                            noOnline
                                                            className={`ml-[-5px] first:ml-0`}
                                                        />
                                                        {isAdmin &&
                                                            <IoIosClose
                                                                size={20}
                                                                className="absolute top-0 right-0 z-[999] bg-white rounded-full cursor-pointer group-hover:block hidden"
                                                                onClick={() => {
                                                                    updateAssigneeHandler({ assignee: item?._id })
                                                                    const filtered = users.filter(assignee => assignee._id !== item._id)
                                                                    setUsers(filtered)
                                                                }}
                                                            />
                                                        }
                                                    </>
                                                )}
                                            </>
                                        ))}
                                        {users?.length > 3 && (
                                            <div className='h-[40px] w-[40px] flex justify-center items-center border border-[#aaa] rounded-full bg-white ml-[-10px] relative'>+{users?.length - 3}</div>
                                        )}
                                    </div>
                                )}
                                {(projectPermissions?.update || isAdmin) &&
                                    <button onClick={() => dispatch(taskActions.setAssigneesModalOpen(true))}>
                                        <IoAdd size={20} />
                                    </button>
                                }
                            </div>
                        </div>

                        {/* DUE DATE OF THE TASK */}
                        <div className='flex w-full md:max-w-[300px] justify-between'>
                            <span className='text-[14px] flex   items-center w-full'><SlCalender size={13} className='mr-[5px]' /> Due Date</span>
                            <input
                                type='datetime-local'
                                value={formattedDate}
                                disabled={!isEdit}
                                className='text-[14px] w-full'
                                onChange={(e) => {
                                    setFormInput(prevState => ({ ...prevState, dueDate: e.target.value }))
                                }}
                            />
                        </div>

                        {/* TASK TIME TRACKER */}
                        <div className='flex w-full md:max-w-[300px] justify-between'>
                            <span className='text-[14px] flex items-center'><LuTimer size={15} className='mr-[5px]' /> Track Time</span>
                            <span className='text-[14px]'>
                                <Timer />
                            </span>
                        </div>

                        {/* TASK PRIORITY */}
                        <div className='flex w-full md:max-w-[300px] justify-between'>
                            <span className='text-[14px] flex items-center'><BsFlagFill size={15} className='mr-[5px]' /> Priority</span>
                            <span className='text-left relative'>
                                <button 
                                    onClick={() => {
                                        if (isEdit) {
                                            if (show.priority) setShow(prevState => ({ ...prevState, priority: false }))
                                            else setShow(prevState => ({ ...prevState, priority: true }))
                                        }
                                    }}
                                    className={`flex gap-2 items-center ${!isEdit && "cursor-default"}`}
                                >
                                    <p className='capitalize'>{formInput.priority}</p>
                                    <BsFlagFill
                                        color={getPriorityColor(formInput.priority)}
                                        title={formInput.priority}
                                    />
                                </button>
                                <div className={`bg-white shadow-md absolute top-[30px] right-0 px-[5px] z-[9] py-[5px] ${show.priority ? "grid" : "hidden"}`} onMouseLeave={() => setShow(prevState => ({ ...prevState, priority: false }))}>
                                    {priorityTypes.map((item, i) => (
                                        <button
                                            key={i}
                                            onClick={() => {
                                                setFormInput(prevState => ({ ...prevState, priority: item.title }))
                                                // updateHandler({ priority: item.title })
                                                setShow(prevState => ({ ...prevState, priority: false }))
                                            }}
                                            className="flex gap-[10px] cursor-pointer py-[3px] items-center border-b last:border-b-0 hover:bg-[#f5f5f5] px-[10px]"
                                        >
                                            <BsFlagFill color={item.color} title={item.title} />
                                            <span className="text-[12px] uppercase select-none text-left">{item.title}</span>
                                        </button>
                                    ))}
                                </div>
                            </span>
                        </div>
                    </div>

                    {/* DESCRIPTION OF THE TASK */}
                    <div className='w-full mt-[20px]'>
                        <RichText
                            readOnly={!isEdit}
                            value={formInput.description}
                            placeholder="Write description here..."
                            onChange={(input) => {
                                setFormInput(prevState => ({ ...prevState, description: input }));
                            }}
                            className='long-richtext'
                        />
                    </div>
                </div>

                {/* ACTIVITIES ON THE TASK */}
                <Activities />

                {/* DELETES TASK */}
                {(projectPermissions?.delete || isAdmin) && (
                    <div className='flex justify-end mt-[30px]'>
                        <DeleteConfirmation
                            onClick={deleteTaskHandler}
                            description="Are you sure you want to delete this task and all of its data? This can not be undone!"
                            component={
                                <div title='Delete Task' className='flex items-center gap-[5px] border px-[10px] py-[5px] text-[12px] bg-[#FF0000] text-white'>
                                    <AiTwotoneDelete size={15} color='#fff' /> Delete
                                </div>
                            }
                            className='border-none rounded-none'
                        />
                    </div>
                )}
            </div>
        </Layout>
    )
}

const priorityTypes = [
    {
        title: "high",
        color: "#FF0000"
    },
    {
        title: "medium",
        color: "#ffc40c"
    },
    {
        title: "low",
        color: "#008000"
    }
]

function getPriorityColor(priority) {
    const priorityColor = priorityTypes.find(item => item.title === priority);
    return priorityColor.color;
}

export default Task;