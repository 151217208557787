import React from 'react';
import Checkbox from '../custom/checkbox';
import { useSelector } from 'react-redux';

const ProjectPermissions = ({updateProjectPermissionHandler=()=>{}, ...props}) => {
    const { projects } = useSelector((state) => state.project);

    function isAllChecked(permission) {
        return (
            permission.view && permission.add && permission.update && permission.delete
        );
    }
    
    function findActive(projectId) {
        return props.projectPermission.find(permission => permission.project === projectId.toString()) || {};
    }

    function toggleAllPermissions(projectId) {
        const currentPermission = findActive(projectId);
        const allChecked = isAllChecked(currentPermission);
        
        updateProjectPermissionHandler(projectId, {
            view: !allChecked,
            add: !allChecked,
            update: !allChecked,
            delete: !allChecked,
        });
    }

    return (
        <div className='mt-[50px]'>
            <h6>Tasks</h6>
            <table className='mt-[10px] relative'>
                <thead>
                    <tr className='bg-[#dcdcdc] py-[120px]'>
                        {permissionColumns.map((col, i) => (
                            <td
                                key={i}
                                style={{ width: col.width, textAlign: col.textAlign}}
                                className='!font-bold !text-[14px] text-left px-[10px] py-[10px]'
                            >{col.header}</td>
                        ))}
                        <td className='text-left'></td>
                    </tr>
                </thead>
                <tbody>
                    {projects.map((item, i) => (
                        <tr key={i} className={`bg-[#f8f8ff] border-b`}>
                            <td className='text-left text-[14px] px-[10px] py-[5px]'>{i+1 || "----"}</td>
                            <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>{item.title || "----"}</td>
                            <td className='text-left text-[14px] px-[10px] py-[5px]'>
                                <Checkbox
                                    checked={isAllChecked(findActive(item._id))}
                                    onChange={() => toggleAllPermissions(item._id)}
                                />
                            </td>
                            {/* <td className='text-left text-[14px] px-[10px] py-[5px]'>
                                <Checkbox
                                    checked={findActive(item._id).view}
                                    onChange={(e) => updateProjectPermissionHandler(item._id, {view: !findActive(item._id).view || false })}
                                />
                            </td> */}
                            <td className='text-left text-[14px] px-[10px] py-[5px]'>
                                <Checkbox
                                    checked={findActive(item._id).add}
                                    onChange={(e) => updateProjectPermissionHandler(item._id, {add: !findActive(item._id).add || false })}
                                />
                            </td>
                            <td className='text-left text-[14px] px-[10px] py-[5px]'>
                                <Checkbox
                                    checked={findActive(item._id).update}
                                    onChange={(e) => updateProjectPermissionHandler(item._id, {update: !findActive(item._id).update || false })}
                                />
                            </td>
                            <td className='text-left text-[14px] px-[10px] py-[5px]'>
                                <Checkbox
                                    checked={findActive(item._id).delete}
                                    onChange={(e) => updateProjectPermissionHandler(item._id, {delete: !findActive(item._id).delete || false })}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

const permissionColumns = [
    { 
        header: 'S.No.', 
        width: '10%',
        textAlign: "left"
    },
    { 
        header: 'Project', 
        width: '50%',
        textAlign: "left"
    },
    { 
        header: 'All', 
        width: '10%',
        textAlign: "left"
    },
    // { 
    //     header: 'View', 
    //     width: '10%',
    //     textAlign: "left"
    // },
    { 
        header: 'Add', 
        width: '10%',
        textAlign: "left"
    },
    { 
        header: 'Update', 
        width: '10%',
        textAlign: "left"
    },
    { 
        header: 'Delete', 
        width: '10%',
        textAlign: "left"
    }
]

export default ProjectPermissions;