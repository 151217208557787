import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    assignees: [],
    modalOpen: false,
    selectedTaskData: null,
    taskAssignees: [],
    activityTrigger: 0,
    assigneesModalOpen: false,
    taskDepartments: [],
    activities: [],
}

const taskSlice = createSlice({
    name: 'task',
    initialState,
    reducers: {
        setAssignees(state, action) {
            state.assignees = action.payload;
        },
        setModalOpen(state, action) {
            state.modalOpen = action.payload;
        },
        setSelectedTaskData(state, action) {
            state.selectedTaskData = action.payload;
        },
        setTaskAssignees(state, action) {
            state.taskAssignees = action.payload;
        },
        setActivityTrigger(state, action) {
            state.activityTrigger = action.payload;
        },
        setAssigneesModalOpen(state, action) {
            state.assigneesModalOpen = action.payload;
        },
        setTaskDepartments(state, action) {
            state.taskDepartments = action.payload;
        },
        setActivities(state, action) {
            state.activities = action.payload;
        }
    }
});

export const taskActions = taskSlice.actions;

export default taskSlice.reducer;