import React from 'react';

const Typing = () => {
    return (
        <div className='flex items-center gap-[5px]'>
            <p className='text-[12px] text-[#00f]'>Typing</p>
            <div className="typing-loader"></div>
        </div>
    )
}

export default Typing;