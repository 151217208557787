import React, { useState, useEffect } from 'react';
import ReactModal from '.';
import { RiEditBoxLine } from "react-icons/ri";
import TextInput from '../components/custom/textInput';
import Label from '../components/custom/label';
import Error from '../components/custom/error';
import Dropdown from '../components/custom/dropdown';
import Button from '../components/custom/button';
import { useDispatch } from 'react-redux';
import { uiActions } from '../store/ui-slice';
import { formatDateForCompare } from '../utils/formatTimestamp';

const UpdateEvent = ({ label, eventTime, onClick, id, departmentId, departments, eventDate }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

    const formatDate = (date) => {
    return formatDateForCompare(date)
  }

  const [event, setEvent] = useState({
    updatedLabel: label,
    updatedTime: eventTime,
    updatedDepartment: departmentId || "",
    updatedDate: formatDate(eventDate),

  });
  const [error, setError] = useState("");

  useEffect(() => {
    if (open) {
      setEvent({
        updatedLabel: label,
        updatedTime: eventTime,
        updatedDepartment: departmentId || "",
        updatedDate: formatDate(eventDate),

      });
      setError("");
    }
  }, [open, label, eventTime, eventDate, departmentId]);

  const handleUpdate = () => {
    if (!event.updatedLabel.trim() || !event.updatedTime || !event.updatedDate || event.updatedDepartment === null) {
      setError("Field must not be empty!");
    } else {
      onClick({
        id,
        label: event.updatedLabel,
        eventTime: event.updatedTime,
        departmentId: event.updatedDepartment === "" ? null : event.updatedDepartment,
        eventDate: event.updatedDate,
      });
      setOpen(false);
      dispatch(uiActions.setPaginationOffset(0))
    }
  };

  const handleClose = () => {
    setEvent({
      updatedLabel: label,
      updatedTime: eventTime,
      updatedDepartment: departmentId || "",
      updatedDate: formatDate(eventDate),
    });
    setOpen(false);
  };

  const handleUpdateEventKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      handleUpdate()
    }
  };

  return (
    <>
      <button
        title='Edit'
        onClick={() => setOpen(true)}
        className='rounded-full flex justify-center items-center text-[#fff] overflow-hidden hover:bg-active hover:text-primary focus:bg-active h-[35px] w-[35px]'
      >
        <RiEditBoxLine size={15} color='#000000' />
      </button>
      <ReactModal open={open} close={handleClose} maxWidth="600px" heading={`Update Event "${label}"`}>
        {error && <Error message={error} />}
        <Label title='Label' />
        <TextInput
          value={event.updatedLabel}
          onChange={(e) => {
            setEvent(prevEvent => ({ ...prevEvent, updatedLabel: e.target.value }));
            setError("");
          }}
          placeholder='Update event'
          className='mt-[5px] mb-[5px]'
          onKeyDown={handleUpdateEventKeyDown}
        />
        <Label title='Event Date' />
        <TextInput
          type='date'
          value={event.updatedDate}
          onChange={(e) => {
            setEvent(prevEvent => ({ ...prevEvent, updatedDate: e.target.value }));
            setError("");
          }}
          placeholder='Update date'
          className='mt-[5px] mb-[5px]'
          onKeyDown={handleUpdateEventKeyDown}
        />
        <Label title='Event Time' />
        <TextInput
          type='time'
          value={event.updatedTime}
          onChange={(e) => {
            setEvent(prevEvent => ({ ...prevEvent, updatedTime: e.target.value }));
            setError("");
          }}
          placeholder='Update time'
          className='mt-[5px] mb-[5px]'
          onKeyDown={handleUpdateEventKeyDown}
        />
        <Label title='Department' />
        <Dropdown
          value={
            event.updatedDepartment === ""
              ? "All"
              : departments?.find(dept => dept?._id === event.updatedDepartment)?.title || "Select Department"
          }
          onChange={(option) => setEvent(prevEvent => ({
            ...prevEvent,
            updatedDepartment: option?._id
          }))}
          options={[{ title: "All", _id: "" }, ...departments]}
          className='mt-[5px]'
        />
        <Button
          label='Update'
          onClick={handleUpdate}
          type="secondary"
          className='mt-[30px]'
        />
      </ReactModal>
    </>
  );
};

export default UpdateEvent;
