import React from 'react'
import UpdateEvent from '../../popups/updateEvent'
import Delete from '../../popups/deleteEvent'
import { DeleteRequest, PutRequest } from '../../utils/request';
import { useSelector } from 'react-redux';

const EventsData = ({data, setTrigger=()=>{}}) => {
    const { accountInfo } = useSelector((state) => state?.account);
    const { departments } = useSelector((state) => state.employee);
    const role = accountInfo?.data.role.title?.toLowerCase();
    const permissions = accountInfo?.permissions;
    
    // Updating an event
    const updateEventHandler = ({ id, label, eventTime, departmentId, eventDate }) => {
        PutRequest(`${process.env.REACT_APP_URL}/event/${id}?userId=${accountInfo?.data._id}`, { label, eventTime, departmentId, eventDate }).then(() => {
            setTrigger(prev => prev + 1);
        }).catch((error) => {
            console.log(error, "update event error");
        });
    };

    // Deleting an event
    const deleteEventHandler = (id) => {
        DeleteRequest(`${process.env.REACT_APP_URL}/event/${id}?userId=${accountInfo?.data._id}`).then(() => {
            setTrigger(prev => prev + 1);
        }).catch((error) => {
            console.log(error, "remove event error");
        });
    };
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-[30px] gap-[10px]">
            {data.map((event) =>
                <div
                    key={event._id}
                    className="rounded-[8px] p-[20px] shadow-md bg-white"
                >
                    <div className="flex justify-between items-center mb-[10px]">
                        <span className="text-[#007bff] text-[12px]">
                            {event.eventDate.toDateString()}  <span className="text-[#ef0145] text-[12px]" >({event?.eventTime})</span>
                        </span>
                        <div className="flex">
                            {(role === "admin" || permissions?.updateEvent) && (
                                <UpdateEvent
                                    label={event.label}
                                    id={event._id}
                                    eventTime={event?.eventTime}
                                    eventDate={event?.eventDate}
                                    departmentId={event?.departmentId?._id}
                                    departments={departments}
                                    onClick={updateEventHandler}
                                />
                            )}
                            {(role === "admin" || permissions?.deleteEvent) && (
                                <Delete
                                    label={`Are you sure you want to delete event "${event.label}"`} 
                                    onClick={() => deleteEventHandler(event._id)} 
                                />
                            )}
                        </div>
                    </div>
                    <div className="pb-[10px]">
                        <p className="text-[#800080] uppercase text-[18px] break-all">{event.label}</p>
                        {event?.departmentId == null ?
                            <p className="text-[#800080] uppercase text-[12px] break-all">(All)</p>
                            :
                            <p className="text-[#800080] uppercase text-[12px] break-all">({event?.departmentId?.title})</p>
                        }
                    </div>
                </div>
            )}
        </div>
    )
}

export default EventsData;