import React from 'react'
import Checkbox from '../custom/checkbox';

const Permissions = ({permissions, updatePermissionHandler=()=>{}, ...props}) => {
    return (
        <div className='mt-[50px]'>
            <h6>{props.label}</h6>
            <table className='mt-[10px] relative'>
                <thead>
                    <tr className='bg-[#dcdcdc] py-[120px]'>
                        {permissionColumns.map((col, i) => (
                            <td
                                key={i}
                                style={{ width: col.width, textAlign: col.textAlign }}
                                className='!font-bold !text-[14px] text-left px-[10px] py-[10px]'
                            >{col.header}</td>
                        ))}
                        <td className='text-left'></td>
                    </tr>
                </thead>
                <tbody>

                    {/* EVENTS PERMISSION */}
                    <tr className={`bg-[#f8f8ff] border-b`}>
                        <td className='text-left text-[14px] px-[10px] py-[5px]'>1</td>
                        <td className='text-left text-[14px] px-[10px] py-[5px]'>Events</td>
                        <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>
                            <Checkbox
                                checked={permissions?.addEvent && permissions?.updateEvent && permissions?.deleteEvent}
                                onChange={() => {
                                    const allChecked = permissions?.addEvent && permissions?.updateEvent && permissions?.deleteEvent;
                                    updatePermissionHandler({
                                        viewEvent: !allChecked,
                                        addEvent: !allChecked,
                                        updateEvent: !allChecked,
                                        deleteEvent: !allChecked
                                    });
                                }}
                            />
                        </td>
                        <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>
                            {/* <Checkbox
                                checked={permissions?.viewEvent || false}
                                onChange={() => updatePermissionHandler({ viewEvent: !permissions?.viewEvent })}
                            /> */}
                        </td>
                        <td className='text-left text-[14px] px-[10px] py-[5px]'>
                            <Checkbox
                                checked={permissions?.addEvent || false}
                                onChange={() => updatePermissionHandler({ addEvent: !permissions?.addEvent })}
                            />
                        </td>
                        <td className='text-left text-[14px] px-[10px] py-[5px]'>
                            <Checkbox
                                checked={permissions?.updateEvent || false}
                                onChange={() => updatePermissionHandler({ updateEvent: !permissions?.updateEvent })}
                            />
                        </td>
                        <td className='text-left text-[14px] px-[10px] py-[5px]'>
                            <Checkbox
                                checked={permissions?.deleteEvent || false}
                                onChange={() => updatePermissionHandler({ deleteEvent: !permissions?.deleteEvent })}
                            />
                        </td>
                    </tr>

                    {/* EMPLOYEES PERMISSION */}
                    <tr className={`bg-[#f8f8ff] border-b`}>
                        <td className='text-left text-[14px] px-[10px] py-[5px]'>2</td>
                        <td className='text-left text-[14px] px-[10px] py-[5px]'>Employees</td>
                        <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>
                            <Checkbox
                                checked={permissions?.addEmployee && permissions?.updateEmployee && permissions?.deleteEmployee}
                                onChange={() => {
                                    const allChecked = permissions?.addEmployee && permissions?.updateEmployee && permissions?.deleteEmployee;
                                    updatePermissionHandler({
                                        viewEmployeePage: !allChecked,
                                        addEmployee: !allChecked,
                                        updateEmployee: !allChecked,
                                        deleteEmployee: !allChecked
                                    });
                                }}
                            />
                        </td>
                        <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>
                            {/* <Checkbox
                                checked={permissions?.viewEmployeePage || false}
                                onChange={() => updatePermissionHandler({ viewEmployeePage: !permissions?.viewEmployeePage })}
                            /> */}
                        </td>
                        <td className='text-left text-[14px] px-[10px] py-[5px]'>
                            <Checkbox
                                checked={permissions?.addEmployee || false}
                                onChange={() => updatePermissionHandler({ addEmployee: !permissions?.addEmployee })}
                            />
                        </td>
                        <td className='text-left text-[14px] px-[10px] py-[5px]'>
                            <Checkbox
                                checked={permissions?.updateEmployee || false}
                                onChange={() => updatePermissionHandler({ updateEmployee: !permissions?.updateEmployee })}
                            />
                        </td>
                        <td className='text-left text-[14px] px-[10px] py-[5px]'>
                            <Checkbox
                                checked={permissions?.deleteEmployee || false}
                                onChange={() => updatePermissionHandler({ deleteEmployee: !permissions?.deleteEmployee })}
                            />
                        </td>
                    </tr>

                    {/* PROJECTS PERMISSION */}
                    <tr className={`bg-[#f8f8ff] border-b`}>
                        <td className='text-left text-[14px] px-[10px] py-[5px]'>3</td>
                        <td className='text-left text-[14px] px-[10px] py-[5px]'>Projects</td>
                        <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>
                            <Checkbox
                                checked={permissions?.viewProject && permissions?.addProject && permissions?.updateProject && permissions?.deleteProject}
                                onChange={() => {
                                    const allChecked = permissions?.viewProject && permissions?.addProject && permissions?.updateProject && permissions?.deleteProject;
                                    updatePermissionHandler({
                                        viewProject: !allChecked,
                                        addProject: !allChecked,
                                        updateProject: !allChecked,
                                        deleteProject: !allChecked
                                    });
                                }}
                            />
                        </td>
                        <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>
                            <Checkbox
                                checked={permissions?.viewProject || false}
                                onChange={() => updatePermissionHandler({ viewProject: !permissions?.viewProject })}
                            />
                        </td>
                        <td className='text-left text-[14px] px-[10px] py-[5px]'>
                            <Checkbox
                                checked={permissions?.addProject || false}
                                onChange={() => updatePermissionHandler({ addProject: !permissions?.addProject })}
                            />
                        </td>
                        <td className='text-left text-[14px] px-[10px] py-[5px]'>
                            <Checkbox
                                checked={permissions?.updateProject || false}
                                onChange={() => updatePermissionHandler({ updateProject: !permissions?.updateProject })}
                            />
                        </td>
                        <td className='text-left text-[14px] px-[10px] py-[5px]'>
                            <Checkbox
                                checked={permissions?.deleteProject || false}
                                onChange={() => updatePermissionHandler({ deleteProject: !permissions?.deleteProject })}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

const permissionColumns = [
    {
        header: 'S.No.',
        width: '10%',
        textAlign: "left"
    },
    {
        header: 'Permission',
        width: '40%',
        textAlign: "left"
    },
    {
        header: 'All',
        width: '10%',
        textAlign: "left"
    },
    {
        header: 'View',
        width: '10%',
        textAlign: "left"
    },
    {
        header: 'Add',
        width: '10%',
        textAlign: "left"
    },
    {
        header: 'Update',
        width: '10%',
        textAlign: "left"
    },
    {
        header: 'Delete',
        width: '10%',
        textAlign: "left"
    }
]

export default Permissions;