import React from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import "./stepProgressBar.css";

const StepProgressBar = ({ page }) => {
    let stepPercentage = 0;
    if (page ===1) {
        stepPercentage = 13;
    } else if (page === 2) {
        stepPercentage = 38;
    } else if (page === 3) {
        stepPercentage = 63;
    } else if (page === 4) {
        stepPercentage = 88;
    } else if (page === 5) {
        stepPercentage = 100;
    } else {
        stepPercentage = 0;
    }
    return (
        <ProgressBar percent={stepPercentage}>
            <Step>
                {({ accomplished, index }) => (
                    <div className="flex flex-col justify-center items-center">
                        <div
                            className={`indexedStep ${accomplished ? "accomplished" : null} pointer-events-none`}
                            // onClick={() => onPageNumberClick("1")}
                        >
                            {index + 1}
                        </div>
                        <span className="mt-[5px] max-sm:hidden">Personal Info</span>
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div className="flex flex-col justify-center items-center">
                        <div
                            className={`indexedStep ${accomplished ? "accomplished" : null} pointer-events-none`}
                            // onClick={() => onPageNumberClick("2")}
                        >
                            {index + 1}
                        </div>
                        <span className="mt-[5px] max-sm:hidden">Verification</span>
                    </div>
                )}
            </Step> 
            <Step>
                {({ accomplished, index }) => (
                    <div className="flex flex-col justify-center items-center">
                        <div
                            className={`indexedStep ${accomplished ? "accomplished" : null} pointer-events-none`}
                            // onClick={() => onPageNumberClick("3")}
                        >
                            {index + 1}
                        </div>
                        <span className="mt-[5px] max-sm:hidden">Company Info</span>
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div className="flex flex-col justify-center items-center">
                        <div
                            className={`indexedStep ${accomplished ? "accomplished" : null} pointer-events-none`}
                            // onClick={() => onPageNumberClick("4")}
                        >
                            {index + 1}
                        </div>
                        <div className="mt-[5px] text-center max-sm:hidden">Choose Plan</div>
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div className="flex flex-col justify-center items-center">
                        <div
                            className={`indexedStep ${accomplished ? "accomplished" : null} pointer-events-none`}
                            // onClick={() => onPageNumberClick("4")}
                        >
                            {index + 1}
                        </div>
                        <div className="mt-[5px] text-center max-sm:hidden">Security</div>
                    </div>
                )}
            </Step>
        </ProgressBar>
    );
};

export default StepProgressBar;
