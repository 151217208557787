import React from 'react'
import Label from '../custom/label';
import TextInput from '../custom/textInput';

const CompanyInfo = ({
    formInput, 
    setFormInput=()=>{}, 
    setError=()=>{},
    onNextClick=()=>{},
}) => {

    //NAME INPUT HANDLER
    const nameHandler = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(/[^A-Za-z\s]/g, '');

        const trimmedValue = cleanedValue.trim();
        if (trimmedValue === "") {
            setFormInput((prevState) => ({
                ...prevState,
                [name]: "" 
            }));
        } else {
            setFormInput((prevState) => ({
                ...prevState,
                [name]: cleanedValue
            }));
        }
        setError("");
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            onNextClick()
        }
    };

    return (
        <div>
            <div className="mb-[10px]">
                <Label
                    title='Company Title'
                    htmlFor="company-title"
                    isImportant
                />
                <TextInput
                    id='company-title'
                    name="companyTitle"
                    value={formInput.companyTitle}
                    onChange={nameHandler}
                    onKeyDown={handleKeyDown}
                />
            </div>
            <div className="mb-[10px]">
                <Label
                    title='Company Full Address'
                    htmlFor="company-full-address"
                    isImportant
                />
                <TextInput
                    id='company-full-address'
                    value={formInput.companyAddress}
                    onChange={(e) => {
                        setFormInput(prevState => ({ ...prevState, companyAddress: e.target.value }));
                        setError("");
                    }}
                    onKeyDown={handleKeyDown}
                />
            </div>
            <div className='flex justify-end items-center mt-[30px]'>
                <button
                    className='w-full max-w-[100px] bg-primary text-default py-[5px]'
                    onClick={onNextClick}
                >Next</button>
            </div>
        </div>
    )
}

export default CompanyInfo;