import React, { useEffect, useState } from "react";
import { TbLayoutKanban } from "react-icons/tb";
import { MdBrowserNotSupported, MdOutlineChecklist } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { GetRequest, PutRequest } from "../../utils/request";
import Layout from "../../layout";
import AddMilestone from "../../popups/addMilestone";
import Loader from "../../components/custom/loader";
import Kanban from "../../components/task/kanban";
import List from "../../components/task/list";
import TaskSocket from '../../socket/task-socket';
import { DragDropContext } from '@hello-pangea/dnd';
// import ReportGenerator from "../../popups/reportGenerator";
import { useParams } from 'react-router-dom';
import { projectActions } from "../../store/project-slice";
import ProjectAction from "../../popups/projectAction";
import { FiEdit2 } from "react-icons/fi";
import { taskActions } from "../../store/task-slice";
import { CgNotes } from "react-icons/cg";
import IconButton from "../../components/custom/iconButton";
import Description from "../../components/project/description";

const Project = () => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const { accountInfo } = useSelector((state) => state.account);
    const { projects, activeProject, projectTrigger, layout, activeProjectMilestones } = useSelector((state) => state.project);
    const [taskTrigger, setTaskTrigger] = useState(0);
    const [loader, setLoader] = useState(false);
    const isAdmin = accountInfo?.data.role?.title?.toLowerCase() === "admin";
    const permissions = accountInfo?.permissions;
    const [showDesc, setShowDesc] = useState(false);

    TaskSocket.useTaskAdded()
    TaskSocket.useTaskUpdate(taskTrigger, setTaskTrigger)

    //FETCHES CURRENT PROJECT DATA
    useEffect(() => {
        if (projectId) {
            GetRequest(`${process.env.REACT_APP_URL}/project/detail/${projectId}`).then((response) => {
                dispatch(projectActions.setActiveProject(response.data));
                setLoader(false);
            }).catch((error) => {
                console.error("Error fetching user details:", error);
            });
            GetRequest(`${process.env.REACT_APP_URL}/project/task/${projectId}`).then((response) => {
                dispatch(projectActions.setActiveProjectMilestones(response.data));
                setLoader(false);
            }).catch((error) => {
                console.error("Error fetching user details:", error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [projectId, projectTrigger]);

    useEffect(() => {
        if (activeProject) {
            GetRequest(`${process.env.REACT_APP_URL}/project/assignee/${activeProject._id}`).then(response => {
                dispatch(taskActions.setAssignees([...response.data, activeProject.admin, activeProject.createdBy]))
            }).catch(error => {
                console.log("err getting assignees list >", error);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeProject, projectTrigger])

    //FETCHES CURRENT PROJECT PERMISSIONS
    useEffect(() => {
        if (accountInfo && activeProject) {
            GetRequest(`${process.env.REACT_APP_URL}/permission?project=${activeProject?._id}&id=${accountInfo?.data._id}`).then((response) => {
                dispatch(projectActions.setProjectPermissions(response.data))
            }).catch((error) => {
                console.error("Error fetching user details:", error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [activeProject, accountInfo])

    const onDragEnd = (result) => {
        const { source, destination } = result;

        // If there's no destination, do nothing
        if (!destination) return;

        // If the item is dropped in the same position, do nothing
        if (destination.droppableId === source.droppableId) return;
        // if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        // Get the source milestone and task
        const sourceMilestoneIndex = activeProjectMilestones.findIndex(milestone => milestone._id === source.droppableId);
        const sourceMilestone = activeProjectMilestones[sourceMilestoneIndex];
        const task = sourceMilestone.tasks[source.index];

        // Remove task from source milestone
        const newSourceTasks = Array.from(sourceMilestone.tasks);
        newSourceTasks.splice(source.index, 1);

        const updatedSourceMilestone = {
            ...sourceMilestone,
            tasks: newSourceTasks,
        };

        // Get the destination milestone
        const destinationMilestoneIndex = activeProjectMilestones.findIndex(milestone => milestone._id === destination.droppableId);
        const destinationMilestone = activeProjectMilestones[destinationMilestoneIndex];

        // Add task to the destination milestone at the correct position
        const newDestinationTasks = Array.from(destinationMilestone.tasks);
        newDestinationTasks.splice(destination.index, 0, task);

        const updatedDestinationMilestone = {
            ...destinationMilestone,
            tasks: newDestinationTasks,
        };

        // Update the milestones array
        const newMilestones = Array.from(activeProjectMilestones);
        newMilestones[sourceMilestoneIndex] = updatedSourceMilestone;
        newMilestones[destinationMilestoneIndex] = updatedDestinationMilestone;

        // Dispatch the new milestones to the Redux store
        dispatch(projectActions.setActiveProjectMilestones(newMilestones));
        PutRequest(`${process.env.REACT_APP_URL}/task/${task._id}`, {
            milestoneId: destinationMilestone?._id,
            updatedBy: accountInfo?.data.fullName
        }).then(response => {
            TaskSocket.emitTaskUpdate(activeProject?._id, response.data, "", "milestone updated")
            // setTaskTrigger(prev => prev + 1);
        }).catch(error => {
            console.log("error updating milestone", error.data)
        })
    };

    return (
        <Layout containerClassName="max-w-none">
            {loader && (
                <Loader />
            )}
            <div className="flex justify-between items-center max-lg:mt-[30px] mt-[50px] border-b py-[5px]">
                <div className="flex items-center gap-[10px]">
                    <h4 className="capitalize">{activeProject?.title}</h4>
                    {(isAdmin || (permissions?.updateProject || permissions?.deleteProject)) && (
                        <ProjectAction
                            icon={<FiEdit2 size={12} color="#aaa" />}
                            // setTrigger={() => dispatch(projectActions.setProjectTrigger(projectTrigger + 1))}
                        />
                    )}
                </div>
                <div className="flex justify-end items-center">
                    <IconButton 
                        title="Project Description"
                        icon={<CgNotes title="Project Description" size={15} className="text-primary" />}
                        className="h-[35px] w-[35px] bg-white"
                        onClick={() => setShowDesc(prev => !prev)}
                    />
                    
                    {/* {isAdmin && (
                        <ReportGenerator />
                    )} */}
                    <div className="flex justify-end items-center px-[30px]">
                        <span className="text-[14px] mr-[10px]">Layout:</span>
                        <button
                            title="Kanban"
                            onClick={() => dispatch(projectActions.setLayout("kanban"))}
                            className={`border-y border-l px-[10px] py-[5px] ${layout === "kanban" ? "bg-[#aaa]" : "bg-white"}`}
                        >
                            <TbLayoutKanban size={18} />
                        </button>
                        <button
                            title="List"
                            onClick={() => dispatch(projectActions.setLayout("list"))}
                            className={`border px-[10px] py-[5px] ${layout === "list" ? "bg-[#aaa]" : "bg-white"}`}
                        >
                            <MdOutlineChecklist size={18} />
                        </button>
                    </div>
                    {(isAdmin || accountInfo?.permissions?.updateProject) && projects?.length > 0 && (
                        <div className="flex gap-[10px]">
                            <AddMilestone selectedProject={activeProject} setTrigger={setTaskTrigger} />
                        </div>
                    )}
                </div>
            </div>

            <div className={showDesc ? "block" : "hidden"}>
                <Description />
            </div>

            <div>
                {projects?.length > 0 ? (
                    <div className="scroll-it">
                        {activeProjectMilestones?.length > 0 ? (
                            layout === "kanban" ? (
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Kanban
                                        milestones={activeProjectMilestones}
                                        projectId={activeProject?._id}
                                        setTrigger={setTaskTrigger}
                                    />
                                </DragDropContext>
                            ) :
                                (
                                    <List
                                        milestones={activeProjectMilestones}
                                        projectId={activeProject?._id}
                                        setTrigger={setTaskTrigger}
                                    />
                                )
                        ) : (
                            <div className='text-center mt-[50px] flex flex-col items-center justify-center'>
                                <p className='text-[20px] flex items-center'> <MdBrowserNotSupported className="mr-[10px]" /> No Task Added!</p>
                                <p>Add Milestone to add task!</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="flex flex-col justify-center items-center text-center h-[50vh]">
                        <h3>No Projects</h3>
                        {isAdmin &&
                            <p className="text-[14px] mt-[10px]">Create Project to add tasks!</p>
                        }
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default Project;