import React, { useEffect, useState } from 'react'
import Layout from '../layout';
import { Link, useParams } from 'react-router-dom';
import { DeleteRequest, GetRequest, PutRequest } from '../utils/request';
import Avatar from '../components/custom/avatar';
import { MdAlternateEmail, MdBlock, MdOutlineDeleteSweep } from 'react-icons/md';
import { FaHashtag } from 'react-icons/fa6';
import { PiGenderIntersexDuotone } from 'react-icons/pi';
import { SiPrivateinternetaccess } from 'react-icons/si';
import { LuCakeSlice } from 'react-icons/lu';
import { formatDate } from '../utils/formatTimestamp';
import { CiCalendarDate } from 'react-icons/ci';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from '../store/chat-slice';
import { TbAlphabetLatin } from "react-icons/tb";
import { AiTwotoneMobile } from 'react-icons/ai';
import { IoChatbubbleOutline } from "react-icons/io5";
import ChatSocket from '../socket/chat-socket';
import { uiActions } from '../store/ui-slice';

const User = () => {
    const { userId } = useParams();
    const [userData, setUserData] = useState(null);
    const dispatch = useDispatch();
    const { currentChat, triggerChat, allMessages } = useSelector((state) => state.chat);
    const { accountInfo } = useSelector((state) => state.account);
    const [trigger, setTrigger] = useState(0);
    const showGender = currentChat?.settings ? currentChat.settings.showGender : true;
    const showBirthday = currentChat?.settings ? currentChat.settings.showBirthday : true;

    useEffect(() => {
        if (accountInfo && userId){
            GetRequest(`${process.env.REACT_APP_URL}/user/profile?userId=${accountInfo?.data?._id}&profileId=${userId}`).then(response => {
                setUserData(response.data)
                dispatch(chatActions.setCurrentChat(response.data));
            }).catch(error => {
                console.log("request accept error >>>", error.data);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[accountInfo, userId, trigger])

    function deleteChatHandler() {
        DeleteRequest(`${process.env.REACT_APP_URL}/message/${currentChat?.convoId}`, {
            data: {
                deletedBy: accountInfo?.data?._id
            }
        }).then((response) => {
            dispatch(chatActions.setTriggerChat(triggerChat + 1))
            dispatch(uiActions.setToastify({
                isSuccess: true,
                message: "Chat deleted successfully!"
            }))
            setTrigger(prev => prev + 1)
        }).catch((error) => {
            console.log(error);
        });
    }

    function blockHandler() {
        PutRequest(`${process.env.REACT_APP_URL}/friend/block/${currentChat?.convoId}`, {
            blockedBy: accountInfo?.data?._id
        }).then((response) => {
            dispatch(chatActions.setCurrentChat({ ...currentChat, blockedBy: response.data }));
            dispatch(chatActions.setTriggerChat(triggerChat + 1))
            setTrigger(prev => prev + 1)
            ChatSocket.emitBlockUser(currentChat?.userData?._id, response.data, accountInfo?.data?._id)
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <Layout>
            <div className="flex md:flex-row max-md:flex-col gap-[20px] bg-white p-[30px] shadow-md my-[50px]">
                <div className='p-[30px] border md:w-[400px] max-md:w-full max-md:max-w-[400px] mx-auto shadow-md inline-flex flex-col items-center justify-center relative max-md:!mt-[15px]'>
                    <Avatar
                        src={currentChat
                            ? currentChat?.blockedBy?.includes(currentChat?.userData?._id)
                                ? ""
                                : currentChat?.settings
                                    ? currentChat?.settings.showAvatar
                                        ? currentChat?.userData?.avatar
                                        : ""
                                    : currentChat?.userData?.avatar
                            : ""
                        }
                        alt={currentChat?.userData?.fullName}
                        noOnline
                        size={60}
                        className='!w-[150px] !h-[150px] object-center object-cover'
                    />
                    <h4 className='mt-[10px] mb-[5px]'>{currentChat?.fullName}</h4>
                    {currentChat?.convoId && (
                        <Link to={`/chat/${currentChat?.convoId}`} className='flex items-center gap-[5px] px-[15px] py-[3px] mt-[30px] shadow-md bg-secondary'> <IoChatbubbleOutline  /> Chat</Link>
                    )}
                </div>
                <div className="w-full">
                    <div className='px-[10px] py-[15px] flex justify-between items-center gap-[10px]'>
                        <div className="capitalize flex items-center"> <TbAlphabetLatin size={18} className="mr-[10px]" /> Full Name</div>
                        <div className="">{currentChat?.userData?.fullName}</div>
                    </div>
                    <hr />
                    <div className='px-[10px] py-[15px] flex justify-between items-center gap-[10px]'>
                        <div className="capitalize flex items-center"> <MdAlternateEmail size={16} className="mr-[10px]" /> Email</div>
                        <div className="">{currentChat?.userData?.email}</div>
                    </div>
                    <hr />
                    <div className='px-[10px] py-[15px] flex justify-between items-center gap-[10px]'>
                        <div className="capitalize flex items-center"> <AiTwotoneMobile size={16} className="mr-[10px]" /> Mobile</div>
                        <div>{currentChat?.userData?.mobile ? `${currentChat?.userData?.stdCode} ${currentChat?.userData?.mobile}` : "---"}</div>
                    </div>
                    <hr />
                    <div className='px-[10px] py-[15px] flex justify-between items-center gap-[10px]'>
                        <div className="capitalize flex items-center"> <FaHashtag size={16} className="mr-[10px]" /> Role</div>
                        <div className="capitalize">{currentChat?.userData?.role?.title}</div>
                    </div>
                    <hr />
                    <div className='px-[10px] py-[15px] flex justify-between items-center gap-[10px]'>
                        <div className="capitalize flex items-center"> <PiGenderIntersexDuotone size={20} className="mr-[10px]" /> Gender</div>
                        <div className="capitalize">
                            {showGender ? currentChat?.userData?.gender : <SiPrivateinternetaccess title="Private" />}
                        </div>
                    </div>
                    <hr />
                    <div className='px-[10px] py-[15px] flex justify-between items-center gap-[10px]'>
                        <div className="capitalize flex items-center"> <LuCakeSlice size={18} className="mr-[10px]" /> Birthday</div>
                        <div className="capitalize">
                            {showBirthday
                                ? formatDate(currentChat?.userData?.birthday)
                                : (<SiPrivateinternetaccess title="Private" />)
                            }
                        </div>
                    </div>
                    <hr />
                    <div className='px-[10px] py-[15px] flex justify-between items-center gap-[10px]'>
                        <div className="capitalize flex items-center"> <CiCalendarDate size={18} className="mr-[10px]" /> Joined On</div>
                        <div className="capitalize">{formatDate(currentChat?.userData?.createdAt)}</div>
                    </div>
                    <hr />
                    {currentChat?.convoId && (
                        <div className="flex flex-wrap justify-end items-center gap-[10px] lg:gap-[20px] w-full mt-[10px]">
                            {/* <button
                                // onClick={removeFriendHandler}
                                className={`flex items-center justify-center p-[5px] text-[12px] font-semibold ${currentChat?.isFriend ? 'text-[#aaa] hover:text-[#000] hover:bg-active cursor-pointer' : 'pointer-events-none text-[#ccc]'}`}
                            >
                                <AiOutlineUserDelete size={18} className="mr-[5px]" /> Remove
                            </button> */}
                            <button
                                onClick={deleteChatHandler}
                                className={`flex items-center justify-center p-[5px] text-[12px] font-semibold ${allMessages.length > 0 ? 'text-[#000] hover:bg-active cursor-pointer' : 'pointer-events-none text-[#ccc]'}`}
                            >   <MdOutlineDeleteSweep size={18} className="mr-[5px]" /> Delete Chat
                            </button>
                            <button
                                onClick={blockHandler}
                                className={`flex items-center justify-center p-[5px] text-[12px] font-semibold ${userData?.convoId ? 'hover:bg-active text-error' : ' pointer-events-none text-[#ccc]'}`}
                            >
                                <MdBlock size={18} className="mr-[5px]" />
                                {currentChat?.blockedBy?.includes(accountInfo?.data._id) ? "Unblock" : "Block"}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    )
}

export default User;