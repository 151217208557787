import React from 'react'
import { trimText } from '../../utils/trimText'
import { formatDateAndTime } from '../../utils/formatTimestamp'

const ContactData = ({data}) => {
    return (
        <table className='mt-[20px] relative'>
            <thead>
                <tr className='bg-[#dcdcdc] py-[120px]'>
                    {columns.map((col, i) => (
                        <td
                            key={i}
                            style={{ width: col.width, textAlign: col.textAlign}}
                            className='!font-bold !text-[14px] text-left px-[10px] py-[10px]'
                        >{col.header}</td>
                    ))}
                    <td className='text-left'></td>
                </tr>
            </thead>
            {data.length > 0 ? (
                <tbody>
                    {data.map((user, i) => (
                        <tr key={i} className={`bg-[#f8f8ff] border-b`}>
                            <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>{i+1}</td>
                            <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>{user.fullName || "----"}</td>
                            <td className='text-left text-[14px] px-[10px] py-[5px]'>{user.email || "----"}</td>
                            <td className='text-left text-[14px] px-[10px] py-[5px]'>{user.phone || "----"}</td>
                            <td className='relative group text-left text-[14px] px-[10px] py-[5px]'>
                                {trimText(user.message, 30) || "----"}
                                <div className='bg-white z-[99] w-full p-[10px] absolute top-[10px] left-0 hidden group-hover:block shadow-md'>
                                    <strong>Message:</strong> <br/>
                                    {user.message}
                                </div>
                            </td>
                            <td className='text-right text-[14px] px-[10px] py-[5px]'>{formatDateAndTime(user.createdAt) || "----"}</td>
                        </tr>
                    ))}
                </tbody>
            ):(
                <p className='text-center w-full absolute top-[70px]'>No Results found!</p>
            )}
        </table>
    )
}

const columns = [
    { 
        header: 'S.No.', 
        width: '5%',
        textAlign: "left"
    },
    { 
        header: 'Full Name', 
        width: '15%',
        textAlign: "left"
    },
    { 
        header: 'Email', 
        width: '20%',
        textAlign: "left"
    },
    { 
        header: 'Phone', 
        width: '10%',
        textAlign: "left"
    },
    { 
        header: 'Message', 
        width: '30%',
        textAlign: "left"
    },
    { 
        header: 'Received On', 
        width: '20%',
        textAlign: "right"
    }
];

export default ContactData;