import React from 'react'
import Avatar from '../custom/avatar';
import { useSelector } from 'react-redux';
import { formatDate } from '../../utils/formatTimestamp';
import isBirthdayToday from '../../utils/isBirthdayToday';
import { FaCakeCandles } from "react-icons/fa6";
import { GiPartyFlags } from "react-icons/gi";

const Birthdays = () => {
    const { birthdays } = useSelector((state) => state?.account);

    return (
        <div className='p-[30px] border w-full shadow-md relative bg-white mt-[20px] lg:mt-[30px] overflow-hidden overflow-y-auto'>
            <div className='flex  gap-2'>
                <h4 className='mb-[5px]'>Upcoming Birthdays</h4>
                <GiPartyFlags size={40} color='#e50914' />
            </div>
            <div className='overflow-hidden overflow-y-auto py-[5px] px-[2px]'>
                <div className='grid gap-[5px] items-start mt-[10px] overflow-y-auto max-h-[150px]'>
                    {birthdays.map((item, i) => (
                        <div key={i} className='flex justify-between mt-3 px-[4px]'>
                            <div className='relative flex justify-center items-center gap-2'>
                                {isBirthdayToday(item?.birthday) && (
                                    <div className='absolute top-[5px] left-[-1px] z-[1] animate-pulse'>
                                        <FaCakeCandles color='#F3548C' />
                                    </div>
                                )}
                                <Avatar
                                    src={item.avatar}
                                    size={42}
                                    className={`${isBirthdayToday(item?.birthday) ? 'border-[#F4C2C2] border-[2px]' : ''}`}
                                />
                                <div>
                                    <p className='text-[13px] font-semibold'>{item?.fullName}</p>
                                    <div className='text-[11px] text-[#8a8a8a]'>
                                        <p>{item?.role?.title}</p>
                                        <p>({item?.role?.departmentId?.title})</p>
                                    </div>
                                </div>
                            </div>
                            {isBirthdayToday(item?.birthday) ?
                                <p className='text-[13px] text-success'>Today</p>
                                :
                                <p className='text-[13px]'>{formatDate(item?.birthday)}</p>
                            }

                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Birthdays;