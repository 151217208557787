import React from 'react'

const IconButton = ({ icon, onClick = () => {}, className = "", ...props }) => {
    return (
        <button
            onClick={onClick}
            className={`rounded-full border flex justify-center items-center overflow-hidden text-[#fff] ${className} ${props.disabled ? "pointer-events-none" : ""}`}
            {...props}
        >{icon}</button>
    )
}

export default IconButton;